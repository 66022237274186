/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import { GET_USER, GET_SHORT_COMPANY_BY_ID } from 'api';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ChatCategoriesModal } from './ChatCategories';
import styles from './MessengerPage.module.scss';
import { getChats, startDirectCall } from '../../api/chat/endpoints/chat';
import MobileChat from './MobileChat';
import DesktopChat from './DesktopChat';
import { useWindowSize } from '../../utils/useWindowSize';
import { useSelectedContext } from 'context/contextSelector';
import { openNotification } from 'tt-ui-lib/core';

const MessengerPage = () => {
  const { width } = useWindowSize();

  const { newMessage, startNewCall, chatFolders, connectedUsers, lastCall } = useSelectedContext(
    (context) => context.messenger
  );
  const { selectedCompany } = useSelectedContext((context) => context.user);
  const authUserID = Cookies.get('userId');
  const navigate = useNavigate();
  const [fullUser, setFullUser] = useState();
  const [idUser, setIdUser] = useState('');
  const [userChatId, setUserChatId] = useState();
  const [chatReceiverID, setChatReceiverID] = useState();
  const [userChatInfo, setUserChatInfo] = useState({});
  const [allChats, setAllChats] = useState([]);
  const [lastMessages, setLastMessages] = useState({});
  const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false);
  const [activeFolderId, setActiveFolderId] = useState('');
  const [allFolderChat, setAllFolderChat] = useState(chatFolders);
  const [isResponsiveChat, setIsResponsiveChat] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [getMore, setGetMore] = useState(false);
  const [lastList, setLastList] = useState(false);
  const [getCompanyInfo] = useLazyQuery(GET_SHORT_COMPANY_BY_ID);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (width <= 1200) {
      setIsResponsiveChat(true);
    } else {
      setIsResponsiveChat(false);
    }
  }, [width]);

  useEffect(() => {
    if (allChats.length > 0) {
      setIsLoadingList(false);
    }
  }, [allChats]);

  useEffect(() => {
    if (lastCall) {
      const chatId = lastCall.chat_id;
      setLastMessages({ ...lastMessages, [chatId || chatReceiverID]: lastCall });
    }
  }, [lastCall]);

  const [getUser] = useLazyQuery(GET_USER);

  const openChatByReceiverID = async (id, userInfo = null) => {
    setChatReceiverID(id);
    if (userInfo) {
      setUserChatInfo(userInfo);
    }
  };

  const closeChat = () => {
    setChatReceiverID(undefined);
    setUserChatId(undefined);
    navigate('/dashboard/messaging');
  };

  const openChatByID = async (id, userInfo, isCompanyInfo) => {
    if (id !== 'empty_chat') {
      setUserChatId(id);
      setUserChatInfo({ ...userInfo, is_company: isCompanyInfo });
    } else {
      await setChatReceiverID(null);
      await setUserChatId('empty_chat');
      await openChatByReceiverID(userInfo.id, userInfo);
    }
  };

  const changeChatOrder = (itemFrom) => {
    const from = allChats.splice(itemFrom, 1)[0];
    allChats.splice(0, 0, from);
  };

  const newMessageSent = (message) => {
    const chatId = message.chat_id;
    let moveFrom = allChats.findIndex((chat) => chat.id === chatId || chatReceiverID);
    if (moveFrom === -1) {
      moveFrom = allChats.findIndex((chat) => chat.id === 'empty_chat');
      const allChatsList = [...allChats];
      allChatsList[moveFrom].id = chatId || chatReceiverID;
      setAllChats(allChatsList);
    }
    changeChatOrder(moveFrom);
    setLastMessages({ ...lastMessages, [chatId || chatReceiverID]: message });
  };

  const createNewChat = (messageData) => {
    const newChat = {
      id: messageData.chat_id,
      name: null,
      created_at: messageData.created_at,
      unread_messages_count: 0,
      partner_id: messageData.receiver_id,
      partner_company_id: null,
      latest_message: messageData,
      partner_id_user: messageData.receiver_id_user,
    };

    setAllChats((prevData) => [newChat, ...prevData]);
  };

  const getChatsList = async () => {
    if (!getMore) {
      setIsLoadingList(true);
      setAllChats([]);
    }
    const data = { first: 10, page };
    const activeProfile = searchParams.get('profile');
    const activeCompany = searchParams.get('company');
    searchParams.delete('profile');
    searchParams.delete('company');
    setSearchParams(searchParams);
    if (selectedCompany?.selectedCompany?.id) {
      data.company_id = selectedCompany.selectedCompany.id;
    }
    const chatListData = await getChats(data);
    if (chatListData.data.chats.length === 0) {
      setAllChats([]);
      setLastList(true);
      setGetMore(false);
      setIsLoadingList(false);
    } else {
      const lastMessagesList = {};
      if (getMore) {
        setGetMore(false);
        setAllChats((current) => {
          const listData = [...current, ...chatListData.data.chats];
          listData.forEach((item) => {
            lastMessagesList[item.id] = item.latest_message;
          });
          setLastMessages(lastMessagesList);
          return listData;
        });
      } else {
        setAllChats(chatListData.data.chats);
        chatListData.data.chats.forEach((item) => {
          lastMessagesList[item.id] = item.latest_message;
        });
        setLastMessages(lastMessagesList);
      }
      setPageCount(chatListData.data.count_pages);
    }

    if (activeCompany) {
      const companyData = await getCompanyInfo({ variables: { id: activeCompany } });
      if (companyData?.data?.showCompanyById) {
        const company = companyData.data.showCompanyById;
        const dataUserChat = {
          status: 'not_blocked',
          avatar: company.logos.length && company.logos[0] && company.logos[0].path,
          first_name: company.company_name,
          last_name: '',
          id: company.id,
          is_company: true,
        };

        if (selectedCompany?.selectedCompany?.id) {
          dataUserChat.company_id = selectedCompany.selectedCompany.id;
        }
        setUserChatInfo(dataUserChat);
        await openChatByReceiverID(activeCompany);
      }
    }

    if (activeProfile) {
      if (activeProfile === authUserID) {
        openNotification({
          message: "You can't create a chat with you",
          type: 'error',
        });
        navigate('/dashboard/messaging');
      } else {
        const userData = await getUser({ variables: { id: activeProfile } });
        await setUserChatInfo(userData.data.user);
        await openChatByReceiverID(activeProfile);
      }
    }
  };

  useEffect(() => {
    getChatsList();
  }, [page]);

  useEffect(() => {
    setChatReceiverID(undefined);
    setUserChatId(undefined);
  }, [selectedCompany]);

  useEffect(() => {
    if (newMessage) {
      const chatId = newMessage.chat_id;
      const moveFrom = allChats.findIndex((chat) => chat.id === chatId);
      if (moveFrom !== -1) {
        changeChatOrder(moveFrom);
        setLastMessages({ ...lastMessages, [chatId]: newMessage });
      } else {
        getChatsList();
      }
    }
  }, [newMessage, selectedCompany]);

  const getChatsByCategory = async (id) => {
    setIsLoading(true);
    setActiveFolderId(id);
    await getChatsList();
    await setIsLoading(false);
  };

  const getUserValue = async () => {
    if (selectedCompany?.selectedCompany?.id) {
      const activeCompanyId = selectedCompany?.selectedCompany?.id;
      await setIdUser(activeCompanyId);
      const companyValue = await getCompanyInfo({ variables: { id: activeCompanyId } });
      if (companyValue?.data?.showCompanyById) {
        const company = companyValue.data.showCompanyById;
        setFullUser({
          first_name: company.company_name,
          last_name: '',
        });
      }
    } else {
      const userId = await Cookies.get('userId');
      await setIdUser(userId);
      const userValue = await getUser({ variables: { id: userId } });

      if (userValue?.data?.user) {
        setFullUser(userValue.data.user);
      }
    }
    await getChatsList();
  };

  useEffect(() => {
    getUserValue();
  }, [selectedCompany]);

  const handleStartDirectCall = async (receiver) => {
    const params = {
      userId: selectedCompany?.selectedCompany?.id || idUser,
      receiverId: receiver.id,
    };
    if (receiver.is_company) {
      params.is_company = true;
    }
    if (selectedCompany?.selectedCompany?.id) {
      params.company_id = selectedCompany.selectedCompany.id;
    }

    const directCall = await startDirectCall(params);
    if (directCall?.data?.success) {
      startNewCall({ ...directCall.data.call, user: receiver });
    }
  };

  const createEmptyChat = () => {
    const emptyChat = {
      id: 'empty_chat',
      created_at: new Date(),
      latest_message: null,
      name: null,
      partner_id: null,
    };

    emptyChat.partner_id_user = userChatInfo;
    setAllChats([emptyChat, ...allChats]);
  };

  const getAllChats = async () => {
    setIsLoading(true);
    setActiveFolderId('');
    await getChatsList();
    await setIsLoading(false);
  };

  const lastMessage = (id) => {
    if (lastMessages[id]) {
      if (lastMessages[id].message?.length > 15) {
        return lastMessages[id].message.slice(0, 15).concat('...');
      }
      if (lastMessages[id].type === 'call') {
        return `${
          lastMessages[id].status.charAt(0).toUpperCase() + lastMessages[id].status.slice(1)
        } call`;
      }
      return lastMessages[id].message;
    }
    return '';
  };

  const fetchMoreUsers = async () => {
    if (page < pageCount && !getMore) {
      const pageNumber = page + 1;
      setPage(pageNumber);
      setGetMore(true);
    }
  };

  const onScrollUsers = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const maxScroll = scrollHeight - scrollTop - 1;
    if (maxScroll <= clientHeight && !lastList) {
      fetchMoreUsers();
    }
  };

  return (
    <div className={styles.pageContainer}>
      {isResponsiveChat ? (
        <MobileChat
          connectedUsers={connectedUsers}
          setIsAddFolderModalOpen={setIsAddFolderModalOpen}
          setActiveFolderId={setActiveFolderId}
          activeFolderId={activeFolderId}
          chatFolders={chatFolders}
          allFolderChat={allFolderChat}
          setAllFolderChat={setAllFolderChat}
          getChatsByCategory={getChatsByCategory}
          allChats={allChats}
          openChatByID={openChatByID}
          lastMessage={lastMessage}
          lastMessages={lastMessages}
          userChatId={userChatId}
          chatReceiverID={chatReceiverID}
          fullUser={fullUser}
          userChatInfo={userChatInfo}
          handleStartDirectCall={handleStartDirectCall}
          newMessageSent={newMessageSent}
          createEmptyChat={createEmptyChat}
          closeChat={closeChat}
          isLoadingList={isLoadingList}
          onScrollUsers={onScrollUsers}
          getMore={getMore}
          isLoading={isLoading}
          createNewChat={createNewChat}
        />
      ) : (
        <DesktopChat
          connectedUsers={connectedUsers}
          setIsAddFolderModalOpen={setIsAddFolderModalOpen}
          activeFolderId={activeFolderId}
          idUser={idUser}
          chatFolders={chatFolders}
          getChatsByCategory={getChatsByCategory}
          allChats={allChats}
          openChatByID={openChatByID}
          lastMessage={lastMessage}
          lastMessages={lastMessages}
          setUserChatId={setUserChatId}
          userChatId={userChatId}
          chatReceiverID={chatReceiverID}
          fullUser={fullUser}
          userChatInfo={userChatInfo}
          handleStartDirectCall={handleStartDirectCall}
          newMessageSent={newMessageSent}
          createEmptyChat={createEmptyChat}
          isLoading={isLoading}
          getAllChats={getAllChats}
          onScrollUsers={onScrollUsers}
          getMore={getMore}
          isLoadingList={isLoadingList}
          createNewChat={createNewChat}
        />
      )}
      <ChatCategoriesModal
        open={isAddFolderModalOpen}
        handleClose={() => setIsAddFolderModalOpen()}
        chats={allChats}
        userId={idUser}
        activeFolderId={activeFolderId}
      />
    </div>
  );
};

export default MessengerPage;
