import { Box, ButtonBase } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Heart2Icon, Image, MessageIcon, OrdersIcon } from 'tt-ui-kit';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppAvatar } from 'components/Avatar';
import styles from '../../../CommunityPage.module.scss';
import { GET_USER } from 'api';
import { useLazyQuery } from '@apollo/client';

const ListItem = ({ user }) => {
  const [openOptions, setOpenOptions] = useState(false);
  const router = useNavigate();
  const [statisUser, setStatusUser] = useState();

  const [getUser] = useLazyQuery(GET_USER, { variables: { id: user.id } });

  const getUserValue = async () => {
    const userValue = await getUser();
    if (userValue.data.user) {
      setStatusUser(userValue.data.user.status);
    }
  };

  const onOpenProfile = (event) => {
    if (event.button === 0) {
      router(`/dashboard/profile/${user?.id}`);
    }
    if (event.button === 1) {
      window.open(`/dashboard/profile/${user?.id}`, '_blank');
    }
  };

  // useEffect(() => {
  //   getUserValue();
  // }, []);

  return (
    <Box className={styles.expertsListItem} key={user.id}>
      <div className={styles.avatar} onMouseDown={onOpenProfile}>
        <AppAvatar
          userName={`${user.first_name} ${user.last_name}`}
          role={user.roles && user.roles[0]?.name}
          src={user.avatarSmall}
          nav={0}
          size="sm"
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
        <div className={styles.info} onMouseDown={onOpenProfile}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <div
                className={styles.name}
                style={
                  !user.experiences && user.experiences.length > 0 ? { marginBottom: 18 } : null
                }
              >
                {`${user.first_name} ${user.last_name}`}
              </div>
              {user.experiences && user.experiences.length > 0 && (
                <div
                  className={styles.gridCardDescription}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 14,
                    marginTop: 4,
                  }}
                >
                  <OrdersIcon style={{ marginRight: 5, width: 14, height: 14 }} />
                  <p>
                    {user.experiences[0]?.title} in{' '}
                    <span style={{ color: '#4CAF50' }}>{user.experiences[0]?.company_name}</span>
                  </p>
                </div>
              )}
            </div>
            <div
              className={styles.gridCardDescription}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 2,
                fontSize: 14,
              }}
            >
              {statisUser === 'blocked' ? (
                <p style={{ color: 'red', marginTop: 2 }}>This user is blocked</p>
              ) : user.ceo_of ? (
                <p>
                  Expert in <span style={{ color: '#143471' }}>{user.ceo_of}</span>
                </p>
              ) : (
                <>
                  {user.roles[0].name === 'expert' ? (
                    <p className={styles.rolesName} style={{ color: '#0ab2ab', marginTop: 2 }}>
                      Expert
                    </p>
                  ) : user.roles[0].name === 'investor' ? (
                    <p className={styles.rolesName} style={{ color: '#3C7097', marginTop: 2 }}>
                      Investor
                    </p>
                  ) : null}
                </>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {user.profession && (
              <>
                <Image src="/images/templates/bag-icon.svg" style={{ marginRight: 5 }} />
                <div className={styles.moreInfo}>{user.profession}</div>
              </>
            )}
          </div>
        </div>
        <div
          className={styles.cardButtons}
          style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
        >
          <div className={styles.buttons}>
            {/* TODO Gregory message */}
            <ButtonBase
              type="button"
              sx={{ marginRight: '26px' }}
              disabled={statisUser === 'blocked'}
            >
              <Heart2Icon
                className={statisUser === 'blocked' ? styles.blockedItemIcon : styles.itemIcon}
              />
            </ButtonBase>
            {user.id !== 'c3c2c22e-2baf-41cc-afbb-1dc3f9d2d1f4' && (
              <ButtonBase
                disabled={statisUser === 'blocked'}
                type="button"
                sx={{ marginRight: '20px' }}
                className={styles.currentButton}
                onClick={() => router(`/dashboard/messaging?profile=${user.id}`)}
              >
                <MessageIcon
                  className={statisUser === 'blocked' ? styles.blockedItemIcon : styles.itemIcon}
                />
              </ButtonBase>
            )}
          </div>
          {/* <ButtonBase
            className={styles.optionButton}
            type="button"
            sx={{ marginRight: '22px' }}
            onClick={() => setOpenOptions(true)}
          >
            <MoreVertIcon width={4} height={14} sx={{ color: '#18397A' }} />
          </ButtonBase>
          {openOptions && (
            <div
              style={{ marginTop: 55, marginRight: -25 }}
              className={styles.optionsWindow}
              onMouseLeave={() => setOpenOptions(false)}
            >
              <ButtonBase className={styles.options} onClick={() => setOpenOptions(false)}>
                Follow
              </ButtonBase>
              <ButtonBase
                className={styles.options}
                onClick={() => setOpenOptions(false)}
                sx={{ marginTop: '16px' }}
              >
                Connect
              </ButtonBase>
            </div>
          )} */}
        </div>
      </div>
    </Box>
  );
};

export default ListItem;
