import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';

import { ReactComponent as DocIco } from '../../../../../../assets/svg/doc.svg';
import { Button, Modal, DownloadIcon } from 'tt-ui-kit';

import styles from './CustomDetail.module.scss';
import { useSelectedContext } from 'context/contextSelector';
import ComingSoon from 'components/ComingSoon';
import QuestionsList from './QuestionsList';
import { downloadFileFromURL } from 'utils/appUtils';
import { URL_CUSTOM_REPORTS_REPORTS } from 'constants/calculatorsPage';
import { getResult, getCustomResultData } from 'api/reports/endpoints/reports';
import { getFiles } from 'api/calc/endpoints/files';

const ttRestHost = `${process.env.REACT_APP_SSO_API}/tt-documents/docs`;
const RESULT_URL = URL_CUSTOM_REPORTS_REPORTS;
const iconStyle = { width: 20, height: 20 };

const statuses = {
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NONE: 'NONE',
  NO_DATA: 'NO_DATA',
};

const CustomResultDetail = ({ params }) => {
  const { user } = useSelectedContext((context) => context.user);
  const { id, setErrorText, setOpenErrorAlert } = params;
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState({ report: false, files: false });
  const [draftName, setDraftName] = useState([]);
  const [reportId, setReportId] = useState(null);
  const [filesList, setFilesList] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);
  const [topicsList, setTopicsList] = useState([]);
  const [report, setReport] = useState([]);
  const [isSoon, setIsSoon] = useState(false);

  const [resultFile, setResultFile] = useState(null);
  const [resultsFilesList, setResultsFilesList] = useState([]);
  const [resultsFilesCodes, setResultsFilesCodes] = useState([]);

  const [redirect, setRedirect] = useState(false);

  const createResultObject = () => {
    // Flatten the scope array and map it to an object for easier lookup
    const questionMap = questionsList.reduce((acc, question, index) => {
      acc[question.id] = {
        question: question.questionText,
        subquestion: null,
        code: question.id,
        title: topicsList.find((topic) => topic.id === question.customReportTopicId).topicText,
        files: [],
      };
      return acc;
    }, {});
    // Reduce the files array to the desired format
    filesList.forEach((file) => {
      if (questionMap[file.questionCode]) {
        questionMap[file.questionCode].files.push(file);
      }
    });

    // Convert the scopeMap object to an array and filter out objects without files
    const resultObject = Object.values(questionMap).filter((item) => item.files.length > 0);
    return resultObject;
  };

  useEffect(() => {
    if (!filesList?.length || !questionsList?.length) return;
    const list = createResultObject();
    setResultsFilesList([...list]);
    setResultsFilesCodes([...list.map((q) => q.code)]);
  }, [questionsList, filesList]);

  const onError = (err) => {
    console.log(err);
    setOpenErrorAlert(true);
    setErrorText(err.message ?? 'Something went wrong');
  };

  const createReport = (questions, topics) => {
    const topTopics = [...topics.filter((topic) => topic.parentTopicId === null)];
    const newReport = topTopics.map((topic) => ({
      ...topic,
      subtopic: topics
        .filter((t) => t.parentTopicId === topic.id)
        .map((t) => ({
          ...t,
          questions: questions.filter((q) => q.customReportTopicId === t.id),
        })),
      questions: questions.filter((q) => q.customReportTopicId === topic.id),
    }));

    setReport(newReport);
  };

  const getReport = () => {
    setIsLoaded((prev) => ({ ...prev, report: false }));
    getCustomResultData({ report_id: reportId })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) {
          const { questions, topics } = data;
          setQuestionsList(questions);
          setTopicsList(topics);
          createReport(questions, topics);
        }
      })
      .catch((err) => onError(err))
      .finally(() => setIsLoaded((prev) => ({ ...prev, report: true })));
  };

  const getReportFiles = () => {
    setIsLoaded((prev) => ({ ...prev, files: false }));
    getFiles({ object_id: reportId })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) setFilesList(data ?? []);
      })
      .catch((err) => onError(err))
      .finally(() => setIsLoaded((prev) => ({ ...prev, files: true })));
  };

  useEffect(() => {
    if (redirect) {
      navigate(RESULT_URL);
    }
  }, [redirect]);

  useEffect(() => {
    getResult({ id })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) {
          const [draft] = data;
          setDraftName(draft.name);
          setReportId(draft.reportId);
        } else {
          setRedirect(true);
        }
      })
      .catch((err) => {
        onError(err);
      });
  }, []);

  useEffect(() => {
    if (!reportId) return;
    getReport();
    getReportFiles();
  }, [reportId]);

  const downloadFile = (url) => {
    downloadFileFromURL(url, resultFile.originalName);
  };

  const generateLink = () => {
    const token = Cookies.get('access_token');
    const headers = {
      authorization: token ? `Bearer ${token}` : '',
      Userid: user.id,
    };
    const par = `?name=${resultFile.uuidName}`;
    const url = `${ttRestHost}/downloadUrl${par}`;

    axios
      .get(url, { headers })
      .then((response) => {
        const { data } = response;
        if (data?.success) {
          downloadFile(data.url);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Modal
        onClick={(e) => e.stopPropagation()}
        open={isSoon}
        onClose={() => setIsSoon(false)}
        title="Coming soon"
      >
        <ComingSoon text="Template creating in development." />
      </Modal>
      <div className={styles.contentBlock}>
        <div className={styles.blockHeader}>
          <div className={styles.nameBlock} onClick={() => navigate(RESULT_URL)}>
            <p>&lt; {draftName}</p>
          </div>
        </div>
        <div className={styles.buttonBlock}>
          <Button
            type="default"
            onClick={() => generateLink()}
            startIcon={<DownloadIcon style={iconStyle} />}
          >
            Download report
          </Button>
          <Button type="default" onClick={() => setIsSoon(true)} startIcon={<DocIco />}>
            Create template
          </Button>
        </div>
        {!isLoaded ? (
          <div>Loading...</div>
        ) : (
          <QuestionsList params={{ report, resultsFilesList, resultsFilesCodes, statuses }} />
        )}
      </div>
    </>
  );
};

export default CustomResultDetail;
