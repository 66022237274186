import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DataTable from 'components/DataTable';
import Typography from '@mui/material/Typography';
import { IconButton, MenuItem, Select } from '@mui/material';
import { Image } from 'tt-ui-kit';
import EditIcon from '@mui/icons-material/Edit';
import { useLazyQuery } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import EnterProfileModal from './EnterProfileModal/EnterProfileModal';
import EditStatusModal from './EditStatusModal/EditStatusModal';
import EditRoleModal from './EditRoleModal/EditRoleModal';
import Filter from './Filter';
import styles from './UsersPage.module.scss';
import { GET_ADMIN_USERS, GET_ADMIN_ROLES_COUNT } from '../../../../api';

const statuses = {
  deleted: 'Deleted',
  blocked: 'Blocked',
  not_blocked: 'Not Blocked',
  recovered: 'Recover',
};

const UsersPage = () => {
  const pageSize = 9;
  const [selectedRole, setSelectedRole] = useState('individual');

  const [adminUsersList, setAdminUsersList] = useState([]);
  const [totalFoundCount, setTotalFoundCount] = useState(0);
  const [adminRolesList, setAdminRolesList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [timeoutID, setTimeoutID] = useState(null);
  const [enterProfileModal, setEnterProfileModal] = useState(false);
  const [editRoleModal, setEditRoleModal] = useState(false);
  const [editStatusModal, setEditStatusModal] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userOldRole, setUserOldRole] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [userFullName, setUserFullName] = useState('');
  const [userId, setUserId] = useState('');
  const [queryFilter, setQueryFilter] = useState({
    limit: pageSize,
    page: 1,
    order_dir: 'desc',
    f: [
      {
        column: 'role',
        operator: 'equals',
        filter: ['individual'],
      },
    ],
  });

  const [filter, setFilter] = useState({});

  const [getAdminUsers] = useLazyQuery(GET_ADMIN_USERS);
  const [getAdminRolesCount] = useLazyQuery(GET_ADMIN_ROLES_COUNT);

  const handleChangeRole = (event, newValue) => {
    setSelectedRole(newValue);
    setCurrentPage(1);
    const newQueryFilter = {
      limit: pageSize,
      page: 1,
      order_by: queryFilter.order_by,
      order_dir: 'desc',
      f: [
        {
          column: 'role',
          operator: 'equals',
          filter: 'individual',
        },
      ],
    };
    newQueryFilter.order_dir = 'desc';
    newQueryFilter.limit = pageSize;
    newQueryFilter.page = 1;
    if (newQueryFilter.f && newQueryFilter.f.length) {
      newQueryFilter.f.map((item) => {
        if (item && item.column === 'role') {
          // eslint-disable-next-line no-param-reassign
          item.filter = newValue;
        }
        return item;
      });
    }
    newQueryFilter.f = [...newQueryFilter.f, ...queryFilter.f.slice(1)];
    console.log(newQueryFilter);
    setQueryFilter(newQueryFilter);
  };

  const handleChangeRoleSelect = (event, newValue) => {
    setQueryFilter((prevState) => {
      const newState = { ...prevState };
      delete newState.f;
      return newState;
    });
    handleChangeRole(event, newValue);
  };

  const getAdminUsersList = async (queryFilterParams) => {
    setLoading(true);
    const users = await getAdminUsers({ variables: { input: queryFilterParams } });
    setTotalFoundCount(0);
    setAdminUsersList([]);
    if (users.data && users.data.adminUsers) {
      setAdminUsersList(users.data.adminUsers.users);
      setTotalFoundCount(users.data.adminUsers.count);
      setLoading(false);
      setPageCount(Math.ceil(users.data.adminUsers.count / pageSize));
    }
  };
  const getAdminRolesList = async (queryFilterParams) => {
    setLoading(true);
    const roles = await getAdminRolesCount({ variables: { input: queryFilterParams } });
    if (roles.data && roles.data.adminRolesCount) {
      setAdminRolesList(roles.data.adminRolesCount);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdminUsersList(queryFilter);
  }, [queryFilter]);

  useEffect(() => {
    getAdminRolesList();
  }, []);

  useEffect(() => {
    if (!editRoleModal && userOldRole !== userRole) {
      setLoading(true);
      getAdminRolesList();
      getAdminUsersList(queryFilter);
    }
  }, [editRoleModal]);

  function getFullName(params) {
    return `${params.row.first_name || ''} ${params.row.last_name || ''}`;
  }
  function getId(params) {
    return `${params.row.id || ''}`;
  }

  function setFullName(params) {
    const [firstName, lastName] = params.value.toString().split(' ');
    return { ...params.row, firstName, lastName };
  }

  const changeFilter = (value) => {
    let filters = queryFilter.f || [];

    const updateFilter = (key, columnName, operator = 'equals') => {
      const filterValue = value[key];
      setFilter((current) => ({ ...current, [key]: filterValue }));

      if (filterValue === 'all') {
        filters = filters.filter((item) => item.column !== columnName);
      } else {
        let isAdded = false;
        filters = filters.map((item) => {
          if (item.column === columnName) {
            isAdded = true;
            return filterValue;
          }
          return item;
        });

        if (!isAdded) {
          filters.push(filterValue);
        }
      }
    };

    if (value.order_by) {
      updateFilter('order_by', 'order_by', 'equals');
    }

    if (value.registration_type) {
      updateFilter('registration_type', 'registration_type', 'equals');
    }

    if (value.kyc) {
      updateFilter('kyc', 'kyc', 'equals');
    }

    if (value.status) {
      updateFilter('status', 'status', 'equals');
    }

    setQueryFilter((current) => ({ ...current, f: filters }));
  };

  const changeOrder = (value) => {
    if (value === 'all') {
      setQueryFilter((current) => ({ ...current, order_by: undefined }));
    } else {
      setQueryFilter((current) => ({ ...current, order_by: value }));
    }
  };

  const searchUser = (value) => {
    setInputValue(value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const timeout = setTimeout(() => {
      setQueryFilter((current) => ({ ...current, q: value }));
    }, 2000);
    setTimeoutID(timeout);
  };

  const changePage = (event, value) => {
    setCurrentPage(value);
    setQueryFilter((current) => ({ ...current, page: value }));
  };

  function parseFullName(value) {
    return String(value)
      .split(' ')
      .map((str) => (str.length > 0 ? str[0].toUpperCase() + str.slice(1) : ''))
      .join(' ');
  }

  function getRoles(params) {
    return `${params.row?.roles?.map((item) => item.name).join(', ')}`;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const renderName = (props) => {
    const { value } = props;
    return (
      <div className={styles.nameColumn}>
        {value ?? ''}
        <Tooltip
          slotProps={{
            arrow: {
              sx: {
                color: '#999999',
              },
            },
            tooltip: {
              sx: {
                fontFamily: 'Montserrat',
                fontSize: '12px',
                padding: '12px 20px',
                border: 'none',
                borderRadius: '12px',
                color: '#FFFFFF',
                backgroundColor: '#999999',
              },
            },
          }}
          title={`Login as ${value}`}
          placement="top"
          arrow
        >
          <IconButton
            onClick={() => {
              setEnterProfileModal(true);
              setUserFullName(value);
            }}
          >
            <Image src="/images/templates/admin-users-pointer-green.svg" />
          </IconButton>
        </Tooltip>
      </div>
    );
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const renderRole = (props) => {
    const { value, id } = props;

    return (
      <div className={styles.rolesColumn}>
        <div className={styles.rolesCollumnWrapper}>
          {value ? value.split(', ').map((item) => <span>{item}</span>) : ''}
        </div>
        <IconButton
          onClick={() => {
            setEditRoleModal(true);
            setUserRole(value);
            setUserOldRole(value);
            setUserId(id);
          }}
        >
          <EditIcon htmlColor="black" fontSize="small" />
        </IconButton>
      </div>
    );
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const renderStatus = (props) => {
    const { value, id, row } = props;
    return (
      <div className={styles.nameColumn}>
        {statuses[value] ?? ''}
        <IconButton
          onClick={() => {
            setEditStatusModal(true);
            setUserStatus(value);
            setUserId(id);
            setUserFullName(`${row.first_name} ${row.last_name}`);
          }}
        >
          <EditIcon htmlColor="black" fontSize="small" />
        </IconButton>
      </div>
    );
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  const renderId = (props) => {
    const { id } = props;
    return (
      <div className={styles.nameColumn}>
        <Tooltip
          slotProps={{
            arrow: {
              sx: {
                color: '#999999',
              },
            },
            tooltip: {
              sx: {
                fontFamily: 'Montserrat',
                fontSize: '12px',
                padding: '12px 20px',
                border: 'none',
                borderRadius: '12px',
                color: '#FFFFFF',
                backgroundColor: '#999999',
              },
            },
          }}
          title={id}
          placement="top"
          arrow
        >
          <Typography className={styles.idColumn}>{id}</Typography>
        </Tooltip>
      </div>
    );
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 88,
      valueGetter: getId,
      renderCell: renderId,
    },
    {
      field: 'fullName',
      headerName: 'Username',
      width: 230,
      valueGetter: getFullName,
      valueSetter: setFullName,
      valueParser: parseFullName,
      renderCell: renderName,
    },
    { field: 'email', headerName: 'Contact', width: 313 },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 180,
      valueGetter: getRoles,
      renderCell: renderRole,
    },
    { field: 'created_at', headerName: 'Registration Date', width: 210 },
    { field: 'ip', headerName: 'IP', width: 140 },
    { field: 'invited_by_name', headerName: 'Invited by', width: 132 },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      renderCell: renderStatus,
    },
    { field: 'kyc', headerName: 'KYC', width: 160 },
  ];

  //  TODO create a request to SSO to get all roles that can be changed
  const roleList = [
    { role: 'admin' },
    { role: 'expert' },
    { role: 'investor' },
    { role: 'business' },
    { role: 'individual' },
    { role: 'validator' },
    { role: 'advisor' },
    { role: 'holder' },
    { role: 'transparenterra' },
  ];

  return (
    <>
      <Tabs
        TabIndicatorProps={{ style: { backgroundColor: '#143471' } }}
        centered
        value={selectedRole}
        onChange={handleChangeRoleSelect}
        className={styles.tabs}
      >
        {adminRolesList.map((item) => (
          <Tab
            value={item.role}
            label={`${item.role} (${item.count})`}
            className={[styles.tabItem, selectedRole === item.role ? styles.activeItem : null]}
            sx={{ margin: '0px 30px', padding: 0, color: '#ff001c' }}
          />
        ))}
      </Tabs>
      <Select
        onChange={handleChangeRoleSelect}
        value={selectedRole}
        size="medium"
        className={styles.topSelect}
      >
        {adminRolesList.map((item) => (
          <MenuItem value={item.role}>
            <span className={styles.topMenuItem}>{`${item.role} (${item.count})`}</span>
          </MenuItem>
        ))}
      </Select>

      <Box className={styles.tableContent}>
        <Box className={styles.tableTotal}>
          <p>Total found: {totalFoundCount} pcs.</p>
        </Box>
        <Filter
          filter={filter}
          inputValue={inputValue}
          changeFilter={changeFilter}
          orderBy={queryFilter.order_by}
          changeOrder={changeOrder}
          searchUser={searchUser}
        />
        <Box sx={{ marginTop: '20px' }}>
          <DataTable
            columns={columns}
            rows={adminUsersList}
            loading={loading}
            pageSize={pageSize}
            currentPage={currentPage}
            pageCount={pageCount}
            changePage={changePage}
          />
        </Box>
      </Box>
      <EnterProfileModal
        userFullName={userFullName}
        open={enterProfileModal}
        handleClose={() => {
          setEnterProfileModal(false);
          setUserFullName('');
        }}
      />
      <EditStatusModal
        userId={userId}
        userStatus={userStatus}
        setAdminUsersList={setAdminUsersList}
        userFullName={userFullName}
        open={editStatusModal}
        handleClose={() => setEditStatusModal(false)}
      />
      <EditRoleModal
        userId={userId}
        userRole={userRole}
        setUserRole={setUserRole}
        open={editRoleModal}
        //  TODO
        // roles={adminRolesList}
        roles={roleList}
        handleClose={() => setEditRoleModal(false)}
      />
    </>
  );
};

export default UsersPage;
