/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import { TextField, CircularProgress, Box, InputBase, Typography } from '@mui/material';
import { AppAvatar } from 'components/Avatar';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import Picker from 'emoji-picker-react';
import { Messages } from '../Messages';
import styles from './MessageList.module.scss';
import { SendMessageBtn } from './SendMessageBtn';
import { SendAudioMessageBtn } from './SendAudioMessageBtn';
import ActionsDropdown from './ActionsDropdown';
import ChatFoldersModal from './ChatFoldersModal';
import { getChatMessages, sendDirectMessage } from '../../../api/chat/endpoints/chat';
import { Button, DropdownMenu, Image, PersonIcon } from 'tt-ui-kit';
import { useSelectedContext } from 'context/contextSelector';
import Badge from '@mui/material/Badge';
import moment from 'moment';
import { openNotification } from 'tt-ui-lib/core';
import { splitLongMessage } from '../../../utils/messenger';

const marketTTUrl = process.env.REACT_APP_MARKET || '';

const ITEM_HEIGHT = 58;
const onlineBadgeSx = {
  '& .MuiBadge-badge': {
    backgroundColor: '#4CAF50',
    right: '-6px',
    top: '6px',
    borderRadius: '50%',
  },
};
export const MessageList = ({ isMobile = false, ...props }) => {
  const {
    chatInfo,
    chatId,
    closeChat,
    startDirectCall,
    messageSent,
    chatReceiverID,
    createChat,
    authUser,
    allChats,
    createNewChat,
  } = props;
  const { startTyping, stopTyping, typingData, newMessage, connectedUsers, lastCall } =
    useSelectedContext((context) => context.messenger);
  const { selectedCompany, user } = useSelectedContext((context) => context.user);
  const [idUser, setIdUser] = useState('');
  const [textMessage, setTextMessage] = useState('');
  const [messages, setMessages] = useState({});
  const [countMessagesPages, setCountMessagesPages] = useState(0);
  const [messageInput, setMessageInput] = useState({});
  const [timeoutTyping, setTimeoutTyping] = useState(0);
  const [messagePage, setMessagePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isOpenEmojiPicker, setIsOpenEmojiPicker] = useState(false);
  const [isOpenChatFoldersModal, setIsOpenChatFoldersModal] = useState(false);
  const [chatSearchInput, setChatSearchInput] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(true);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const messageType = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!messageType.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (messageType.current.offsetHeight !== height) {
        setHeight(messageType.current.offsetHeight);
      }
    });

    resizeObserver.observe(messageType.current);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [messageType.current, chatInfo]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const scrollToBottom = () => {
    const messagesContent = document.getElementById('messagesContent');
    if (messagesContent) {
      messagesContent.scrollTop = messagesContent.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [height]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIdUser(Cookies.get('userId'));
  }, []);

  useEffect(() => {
    if (lastCall) {
      const dateAsKey = moment(lastCall.created_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
      if (Object.keys(messages).includes(dateAsKey)) {
        const messagesList = messages;
        messagesList[dateAsKey].push(lastCall);
        setMessages({ ...messagesList });
      } else {
        const newCallWithDate = { [dateAsKey]: [lastCall] };
        setMessages({ ...messages, ...newCallWithDate });
      }
      setTimeout(() => {
        scrollToBottom();
      }, 400);
    }
  }, [lastCall]);

  useEffect(() => {
    if (newMessage) {
      const dateAsKey = moment(newMessage.created_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
      const newMessageWithDate = { [dateAsKey]: [newMessage] };
      if (
        newMessage &&
        (newMessage.chat_id === chatReceiverID ||
          newMessage.chat_id === chatId ||
          newMessage.sender_id === chatReceiverID)
      ) {
        const messagesContent = document.getElementById('messagesContent');
        if (Object.keys(messages).includes(dateAsKey)) {
          const messagesList = messages;
          messagesList[dateAsKey].push(newMessage);
          setMessages(messagesList);
        } else {
          setMessages({ ...messages, ...newMessageWithDate });
        }
        setTimeout(() => {
          messagesContent.scrollTop = messagesContent.scrollHeight;
        }, 100);
      }
    } else {
      setMessages({});
    }
  }, [chatId, newMessage, chatReceiverID]);

  useEffect(() => {
    if (chatInfo) {
      setMessageInput({
        userId: idUser,
        message: textMessage,
        receiverId: chatInfo.id,
      });
    }
  }, [chatInfo, textMessage]);

  const getChatMessagesList = async (chat, pageIndex, notEmpty) => {
    const userId = await Cookies.get('userId');
    await setIdUser(userId);

    const formData = {
      receiverId: chatReceiverID,
      chatId: chat,
      userId: userId,
      page: pageIndex,
    };

    if (selectedCompany?.selectedCompany?.id) {
      formData.company_id = selectedCompany.selectedCompany.id;
    }

    const chatMessages = await getChatMessages(formData);

    const groupedByDate = (chatMessages?.data?.messages || []).reduce((groups, message) => {
      const groupsTemp = groups;

      const date = moment(message.created_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
      const group = groupsTemp[date] || [];

      group.push(message);
      groupsTemp[date] = group;

      return groupsTemp;
    }, {});

    let messagesList = notEmpty && groupedByDate ? messages : {};
    const newFetchedDates = Object.keys(groupedByDate || {});

    newFetchedDates.forEach((date) => {
      if (date in messagesList) {
        messagesList[date] = groupedByDate[date].concat(messagesList[date]);
      } else {
        messagesList = {
          [date]: groupedByDate[date],
          ...messagesList,
        };

        messagesList = Object.fromEntries(
          Object.entries(messagesList).sort(([dateA], [dateB]) => moment(dateA).diff(moment(dateB)))
        );
      }
    });

    setMessages(messagesList);
    setCountMessagesPages(chatMessages.data.count_pages);

    setTimeout(() => {
      if (!pageIndex) {
        scrollToBottom();
      }
      setLoadingMessage(false);
    });
  };

  const fetchMoreMessages = async (page) => {
    setMessagePage((current) => current + 1);
    setLoading(true);
    await getChatMessagesList(chatId, page, true);
    setLoading(false);
  };

  const onScrollChat = (e) => {
    const { offsetHeight, scrollHeight, scrollTop } = e.target;
    const contentHeight = scrollHeight - offsetHeight;
    const page = messagePage + 1;
    const oldHeight = scrollHeight;
    if (countMessagesPages >= page) {
      if (contentHeight > 0 && scrollTop === 0 && !loading) {
        fetchMoreMessages(page).then(() => {
          const messagesContent = document.getElementById('messagesContent');
          if (messagesContent) {
            setTimeout(() => {
              if (messagesContent.scrollHeight === 655) {
                messagesContent.scrollTo({ top: oldHeight - 52 });
              } else {
                messagesContent.scrollTo({
                  top: messagesContent.scrollHeight - oldHeight - 52,
                });
              }
            });
          }
        });
      }
    }
  };

  const getChatMessagesByReceiver = async (receiver, chat) => {
    const userId = await Cookies.get('userId');
    await setIdUser(userId);

    const formData = {
      chatId: chat,
      receiverId: receiver,
      userId: userId,
      is_company: chatInfo.is_company,
    };

    if (selectedCompany?.selectedCompany?.id) {
      formData.company_id = selectedCompany.selectedCompany.id;
    }

    setLoadingMessage(true);
    const chatMessages = await getChatMessages(formData);

    if (chatMessages?.data?.success) {
      const groupedByDate = (chatMessages?.data?.messages || []).reduce((groups, message) => {
        const groupsTemp = groups;

        const date = moment(message.created_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
        const group = groupsTemp[date] || [];

        group.push(message);
        groupsTemp[date] = group;

        return groupsTemp;
      }, {});

      let messagesList = groupedByDate ? messages : {};
      const newFetchedDates = Object.keys(groupedByDate || {});

      newFetchedDates.forEach((date) => {
        if (date in messagesList) {
          messagesList[date] = groupedByDate[date].concat(messagesList[date]);
        } else {
          messagesList = {
            [date]: groupedByDate[date],
            ...messagesList,
          };

          messagesList = Object.fromEntries(
            Object.entries(messagesList).sort(([dateA], [dateB]) =>
              moment(dateA).diff(moment(dateB))
            )
          );
        }
      });

      if (messagesList) {
        setMessages(messagesList);
        setCountMessagesPages(chatMessages?.data?.count_pages || 1);
      } else {
        setCountMessagesPages(0);
        setMessages({});
        if (!chat) {
          createChat();
        }
      }
      setLoadingMessage(false);
    }
  };

  useEffect(() => {
    if (chatId === undefined && chatReceiverID) {
      getChatMessagesByReceiver(chatReceiverID, chatId);
    }
  }, [chatId, chatReceiverID]);

  useEffect(() => {
    if (chatId && chatId !== 'empty_chat') {
      setLoadingMessage(true);
      setMessagePage(1);
      const getFirstPageMessages = async () => {
        await getChatMessagesList(chatId, undefined, false);
        scrollToBottom();
      };
      getFirstPageMessages();
    } else {
      setLoadingMessage(false);
    }
  }, [chatId]);

  const handleSendMessage = async () => {
    if (messageInput && messageInput.message.trim() !== '' && !isMessageSending) {
      setIsMessageSending(true);
      const formData = { ...messageInput };
      if (chatInfo.is_company) {
        formData.is_company = true;
      }
      if (selectedCompany?.selectedCompany?.id) {
        formData.company_id = selectedCompany.selectedCompany.id;
      }

      const messageParts = splitLongMessage(formData.message, 10000);

      for (let i = 0; i < messageParts.length; i++) {
        formData.message = messageParts[i];
        // eslint-disable-next-line no-await-in-loop
        const messageData = await sendDirectMessage(formData);

        if (messageData?.data?.success) {
          const dateAsKey = moment(
            messageData.data.message.created_at,
            'YYYY-MM-DD HH:mm:ss'
          ).format('YYYY-MM-DD');

          if (Object.keys(messages).length === 0) {
            if (
              !allChats?.length ||
              !allChats.find((chat) => chat.id === messageData?.data?.message?.chatId)
            ) {
              createNewChat(messageData.data.message);
            }
            setMessages({ [dateAsKey]: [messageData.data.message] });
            messageSent(messageData.data.message);
            setTextMessage('');
          } else {
            if (Object.keys(messages).includes(dateAsKey)) {
              const messagesList = messages;
              messagesList[dateAsKey].push(messageData.data.message);
              setMessages(messagesList);
            } else {
              const updatedMessages = { [dateAsKey]: [messageData.data.message] };
              setMessages({ ...messages, ...updatedMessages });
            }
            messageSent(messageData.data.message);
            setTextMessage('');
          }

          setTimeout(() => {
            scrollToBottom();
          });
        } else {
          openNotification({
            message: messageData?.response?.data?.message || 'Something went wrong, try again',
            type: 'error',
          });
          break;
        }
      }

      setIsMessageSending(false);
    }
  };

  const sendMessage = async (e = null) => {
    if (e && e.key === 'Enter' && e.key !== 'Shift+Enter') {
      if (!e.getModifierState('Shift')) {
        e.preventDefault();
        await handleSendMessage();
      }
    }
  };

  function handleStopTyping() {
    stopTyping({
      chat_id: chatId || chatReceiverID,
      receiver_id: chatInfo.id,
      name: authUser && `${authUser.first_name} ${authUser.last_name}`,
    });
  }

  const onChangeMessage = (value) => {
    startTyping({
      chat_id: chatId || chatReceiverID,
      receiver_id: chatInfo.id,
      name: authUser && `${authUser.first_name} ${authUser.last_name}`,
    });
    clearTimeout(timeoutTyping);
    const timeout = setTimeout(handleStopTyping, 2000);
    setTimeoutTyping(timeout);
    setTextMessage(value);
  };

  const onEmojiClick = (data) => {
    onChangeMessage(`${textMessage} ${data.emoji}`);
  };

  const onActionClick = (type) => {
    if (type === 'add_to_folder') {
      setIsOpenChatFoldersModal(true);
    }
    if (type === 'call') {
      startDirectCall(chatInfo);
      handleClose();
    }
  };

  return (
    <div className={styles.contentBox}>
      {chatId || chatReceiverID ? (
        <>
          <div className={styles.header}>
            <div className={styles.userInfo}>
              {isMobile && (
                <Button type="icon" className={styles.backButton} onClick={() => closeChat()}>
                  <Image src="/images/chat/backIcon.svg" height={24} width={24} />
                </Button>
              )}
              {chatInfo ? (
                <Link
                  color="inherit"
                  underline="none"
                  to={
                    chatInfo?.is_company
                      ? `${marketTTUrl}/dashboard/company/${chatInfo.is_company}`
                      : `/dashboard/profile/${chatInfo.id}`
                  }
                  className={styles.links}
                >
                  <div className={styles.userInfoBlock}>
                    <div className={styles.avatar}>
                      <AppAvatar
                        nav={0}
                        size="sm"
                        userName={`${chatInfo.first_name} ${chatInfo.last_name}`}
                        src={chatInfo.avatar}
                        isOnline={false}
                      />
                    </div>
                    <div className={styles.nameAndAge}>
                      <div
                        className={styles.name}
                      >{`${chatInfo.first_name} ${chatInfo.last_name}`}</div>
                      {/* TODO */}
                      {chatInfo.status === 'blocked' ? (
                        <div className={`${styles.status} ${styles.deleteStatus}`}>
                          Blocked account
                        </div>
                      ) : chatInfo.status === 'not_blocked' ? (
                        <div className={styles.status}>
                          <Badge
                            sx={onlineBadgeSx}
                            badgeContent=" "
                            variant="dot"
                            invisible={!connectedUsers.includes(chatInfo.id)}
                          >
                            {connectedUsers.includes(chatInfo.id) ? 'Online' : 'Offline'}
                          </Badge>
                        </div>
                      ) : (
                        <div className={`${styles.status} ${styles.deleteStatus}`}>
                          User deleted
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              ) : (
                <div className={styles.userInfoBlock}>
                  <div className={styles.iconDeleted}>
                    <PersonIcon style={{ width: 24, height: 24 }} />
                  </div>
                  <div className={styles.nameAndAge}>
                    <div className={styles.name}>Deleted account</div>
                    {/* TODO */}
                  </div>
                </div>
              )}
            </div>
            {chatSearchInput && (
              <div className={styles.inputArea}>
                <div className={styles.inputGroup}>
                  <InputBase className={styles.chatSearchInput} />
                  <div className={styles.upAndDownIcons}>
                    <Button type="icon">
                      <Image
                        className={styles.upOrDownIcon}
                        src="/images/chat/downIcon.svg"
                        width={15}
                        height={15}
                      />
                    </Button>
                    <Button type="icon">
                      <Image
                        className={styles.upOrDownIcon}
                        src="/images/chat/upIcon.svg"
                        width={15}
                        height={15}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {!chatSearchInput ? (
              <div className={styles.chatButtons}>
                {chatInfo && (
                  <Button
                    type="icon"
                    className={styles.buttonOptions}
                    onClick={() => startDirectCall(chatInfo)}
                  >
                    <Image src="/images/chat/call-icon.svg" width={18} height={18} />
                  </Button>
                )}
                <Button
                  type="icon"
                  className={styles.buttonOptions}
                  onClick={() => setChatSearchInput(true)}
                >
                  <Image src="/images/chat/chatTopSearchIcon.svg" width={18} height={18} />
                </Button>
                <div className={styles.menuButtons}>
                  <Button type="icon" className={styles.buttonOptions} onClick={handleClick}>
                    <Image src="/images/templates/options-icon.svg" width={8} height={14} />
                  </Button>
                  <DropdownMenu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        marginLeft: '27px',
                      },
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <ActionsDropdown onActionClick={onActionClick} />
                  </DropdownMenu>
                </div>
              </div>
            ) : (
              <div className={styles.chatButtons}>
                <button
                  type="button"
                  className={styles.buttonOptions}
                  onClick={() => setChatSearchInput(false)}
                >
                  <Image src="/images/close.svg" width={15} height={15} />
                </button>
              </div>
            )}
          </div>
          {loadingMessage && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: 80,
                zIndex: 10,
                backgroundColor: '#ffffff',
              }}
            >
              <CircularProgress
                sx={{
                  width: '40px !important',
                  height: '40px !important',
                  marginBottom: '110px',
                }}
              />
            </div>
          )}
          {(chatId || chatReceiverID) && (
            <div
              className={styles.chat}
              style={{ height: `calc(100% - ${height + 90}px)` }}
              id="messagesContent"
              onScroll={onScrollChat}
            >
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                  <CircularProgress sx={{ width: '20px !important', height: '20px !important' }} />
                </Box>
              )}
              <Messages
                messages={messages}
                idUser={idUser}
                selectedCompanyId={selectedCompany?.selectedCompany?.id || null}
                startDirectCall={() => startDirectCall(chatInfo)}
              />
            </div>
          )}
          {chatInfo ? (
            chatInfo.status === 'blocked' ? (
              <Box className={styles.deleteUserMessageBlock}>
                <Image
                  src="/images/warning.svg"
                  width={20}
                  height={20}
                  className={styles.typingIcon}
                />
                <Typography className={styles.deleteUserMessage}>
                  This account has been blocked, you cannot continue communicating with this account
                </Typography>
              </Box>
            ) : (
              <div className={styles.typeMessage} ref={messageType}>
                <div className={styles.typing}>
                  {typingData &&
                    typingData.chat_id === chatId &&
                    (typingData.receiver_id === user?.id ||
                      typingData.receiver_id === selectedCompany?.selectedCompany?.id) && (
                      <p>
                        <Image
                          src="/images/chat/pen-icon.svg"
                          width={11}
                          height={11}
                          className={styles.typingIcon}
                        />
                        {typingData.name} is typing...
                      </p>
                    )}
                </div>
                <div className={styles.typeInput}>
                  <div className={styles.emojiPicker}>
                    {isOpenEmojiPicker && (
                      <div
                        className={styles.emojiPickerDropdown}
                        onMouseLeave={() => setIsOpenEmojiPicker(false)}
                      >
                        <Picker onEmojiClick={(e, data) => onEmojiClick(e, data)} />
                      </div>
                    )}
                    <Button
                      className={styles.bottomButton}
                      type="icon"
                      onClick={() => setIsOpenEmojiPicker((current) => !current)}
                    >
                      <Image src="/images/chat/emoji-icon.svg" width={24} height={24} />
                    </Button>
                  </div>
                  <div className={styles.textFieldDiv}>
                    <TextField
                      variant="standard"
                      fullWidth
                      multiline
                      placeholder="Type a message"
                      /* label="Type a message" */
                      className={styles.textField}
                      maxRows={4}
                      cols={60}
                      InputProps={{ disableUnderline: true }}
                      onChange={(e) => onChangeMessage(e.target.value)}
                      onKeyDown={(e) => sendMessage(e)}
                      value={textMessage}
                    />
                  </div>
                  <Button
                    className={styles.bottomButton}
                    type="icon"
                    onClick={() => console.log(textMessage)}
                  >
                    <Image src="/images/chat/addFileIcon.svg" width={24} height={24} />
                  </Button>
                </div>
                <div className={styles.audioMessage}>
                  {textMessage.trim().length > 0 ? (
                    <SendMessageBtn
                      disabled={isMessageSending}
                      onClick={() => handleSendMessage()}
                    />
                  ) : (
                    <SendAudioMessageBtn />
                  )}
                </div>
              </div>
            )
          ) : (
            <Box className={styles.deleteUserMessageBlock}>
              <Image
                src="/images/warning.svg"
                width={20}
                height={20}
                className={styles.typingIcon}
              />
              <Typography className={styles.deleteUserMessage}>
                This account has been deleted, you cannot continue communicating with this account
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <div className={styles.noSelectedChat}>Select a chat to start chatting</div>
      )}
      <ChatFoldersModal
        chatId={chatId}
        open={isOpenChatFoldersModal}
        onClose={() => setIsOpenChatFoldersModal((current) => !current)}
      />
    </div>
  );
};
