import { axiosClient, axiosSSOConfigPrivate } from '../../api';
import {
  API_CALC_GET_QUESTIONS,
  API_RESULT_GET_URL,
  API_DRAFT_BY_ID_GET_URL,
  API_DRAFTS_LIST,
  API_DRAFT_DELETE_URL,
  API_CALC_PRELIMINARY,
  API_RESULTS_GET_URL,
  API_RESULT_ANSWER_GET_URL,
} from '../urls';

export const getQuestions = async (params) =>
  axiosClient.get(API_CALC_GET_QUESTIONS, axiosSSOConfigPrivate({}, { params: params }));

// export const createDraft = async (params) => axiosClient.post(API_DRAFTS_NEW_URL, params);
const transformDraft = (draft) => ({
  ...draft,
  type: draft?.calculatorType?.code,
  factorId: draft.carbon ? draft.carbon.ukGhgCollectionId : null,
  periodReportDate: draft.periodReportDate != null ? new Date(draft.periodReportDate) : null,
  periodReportDateEnd:
    draft.periodReportDateEnd != null ? new Date(draft.periodReportDateEnd) : null,
  createdAt: draft.createdAt != null ? new Date(draft.createdAt) : null,
  updatedAt: draft.updatedAt != null ? new Date(draft.updatedAt) : null,
  stateGraphics: draft.stateGraphics != null ? JSON.parse(draft.stateGraphics) : null,
});

const transformSingle = (resp) => {
  const { data, success } = resp;
  if (!success) {
    return resp;
  }
  return { data: transformDraft(data), success };
};

const transformArray = (resp) => {
  const { data, success } = resp;
  if (!success) {
    return resp;
  }
  return {
    data: [...data.map((draft) => ({ ...transformDraft(draft) }))],
    success,
  };
};

export const deleteDraft = async (params) =>
  axiosClient.post(API_DRAFT_DELETE_URL, params, axiosSSOConfigPrivate());

export const getCalcDraftById = async (params) =>
  axiosClient.get(
    API_DRAFT_BY_ID_GET_URL,
    axiosSSOConfigPrivate(
      {},
      { params: params, shouldTransformResponse: transformSingle, shouldSnakeToCamel: true }
    )
  );

export const getCalcDrafts = async (params) =>
  axiosClient.get(
    API_DRAFTS_LIST,
    axiosSSOConfigPrivate(
      {},
      { params: params, shouldTransformResponse: transformArray, shouldSnakeToCamel: true }
    )
  );

export const getResult = async (params) =>
  axiosClient.get(
    API_RESULT_GET_URL,
    axiosSSOConfigPrivate(
      {},
      { params: params, shouldTransformResponse: transformSingle, shouldSnakeToCamel: true }
    )
  );

export const getResultAnswer = async (params) =>
  axiosClient.get(
    API_RESULT_ANSWER_GET_URL,
    axiosSSOConfigPrivate({}, { params: params, shouldSnakeToCamel: true })
  );

export const getResults = async (params) =>
  axiosClient.get(
    API_RESULTS_GET_URL,
    axiosSSOConfigPrivate(
      {},
      { params: params, shouldTransformResponse: transformArray, shouldSnakeToCamel: true }
    )
  );

export const preliminaryAssessment = async (params, headers) =>
  axiosClient.get(
    API_CALC_PRELIMINARY,
    axiosSSOConfigPrivate({}, { params: params, shouldSnakeToCamel: true })
  );
