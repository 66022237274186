import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';

import NavigateNext from '@mui/icons-material/NavigateNext';
import { ReactComponent as GeneratingIco } from '../../../../../../assets/svg/generating.svg';
import { ReactComponent as DocIco } from '../../../../../../assets/svg/doc.svg';
import { Button, Modal } from 'tt-ui-kit';

import styles from './CustomDetail.module.scss';
import { useSelectedContext } from 'context/contextSelector';
import ComingSoon from 'components/ComingSoon';
import QuestionsList from './QuestionsList';
import { URL_CUSTOM_REPORTS_DRAFTS } from 'constants/calculatorsPage';
import {
  getReportDraftById,
  getCustomDraftData,
  setCustomDraftData,
  getCustomResultData,
} from 'api/reports/endpoints/reports';
import { getFiles } from 'api/calc/endpoints/files';

const ttRestHost = `${process.env.REACT_APP_SSO_API}/tt-ai`;
const DRAFT_URL = URL_CUSTOM_REPORTS_DRAFTS;

const statuses = {
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NONE: 'NONE',
  NO_DATA: 'NO_DATA',
};

const CustomDraftDetail = ({ params }) => {
  const { user } = useSelectedContext((context) => context.user);
  const { id, setErrorText, setOpenErrorAlert } = params;
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState({ report: false, files: false });
  const [draftName, setDraftName] = useState([]);
  const [reportId, setReportId] = useState(null);
  const [filesList, setFilesList] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);
  const [topicsList, setTopicsList] = useState([]);
  const [report, setReport] = useState([]);
  const [isWarningOpened, setIsWarningOpened] = useState(false);
  const [isReportModalOpened, setIsReportModalOpened] = useState(false);
  const [isSoon, setIsSoon] = useState(false);

  const [resultsFilesList, setResultsFilesList] = useState([]);
  const [resultsFilesCodes, setResultsFilesCodes] = useState([]);
  const [needSave, setNeedSave] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const onProceedDraft = () => {
    window.location.replace(`${process.env.REACT_APP_REPORT}/editReport/${id}`);
  };

  const isDisabled = !questionsList.some(
    (q) => !!q.questionText && q.state !== statuses.PROCESSING
  );

  const createResultObject = () => {
    // Flatten the scope array and map it to an object for easier lookup
    const questionMap = questionsList.reduce((acc, question, index) => {
      acc[question.id] = {
        question: question.questionText,
        subquestion: null,
        code: question.id,
        title: topicsList.find((topic) => topic.id === question.customReportTopicId).topicText,
        files: [],
      };
      return acc;
    }, {});
    // Reduce the files array to the desired format
    filesList.forEach((file) => {
      if (questionMap[file.objectSubcode]) {
        questionMap[file.objectSubcode].files.push(file);
      }
    });

    // Convert the scopeMap object to an array and filter out objects without files
    const resultObject = Object.values(questionMap).filter((item) => item.files.length > 0);
    return resultObject;
  };

  useEffect(() => {
    if (!filesList?.length || !questionsList?.length) return;
    const list = createResultObject();
    setResultsFilesList([...list]);
    setResultsFilesCodes([...list.map((q) => q.code)]);
  }, [questionsList, filesList]);

  const onError = (err) => {
    console.log(err);
    setOpenErrorAlert(true);
    setErrorText(err.message ?? 'Something went wrong');
  };

  const createReport = (questions, topics) => {
    const topTopics = [...topics.filter((topic) => topic.parentTopicId === null)];
    const newReport = topTopics.map((topic) => ({
      ...topic,
      subtopic: topics
        .filter((t) => t.parentTopicId === topic.id)
        .map((t) => ({
          ...t,
          questions: questions.filter((q) => q.customReportTopicId === t.id),
        })),
      questions: questions.filter((q) => q.customReportTopicId === topic.id),
    }));

    setReport(newReport);
  };

  const getReport = () => {
    setIsLoaded((prev) => ({ ...prev, report: false }));
    getCustomDraftData({ report_id: reportId })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) {
          const { questions, topics } = data;
          setQuestionsList(questions);
          setTopicsList(topics);
          createReport(questions, topics);
        }
      })
      .catch((err) => onError(err))
      .finally(() => setIsLoaded((prev) => ({ ...prev, report: true })));
  };

  const getReportFiles = () => {
    setIsLoaded((prev) => ({ ...prev, files: false }));
    getFiles({ object_id: reportId })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) setFilesList(data ?? []);
      })
      .catch((err) => onError(err))
      .finally(() => setIsLoaded((prev) => ({ ...prev, files: true })));
  };

  useEffect(() => {
    if (redirect) {
      navigate(DRAFT_URL);
    }
  }, [redirect]);

  useEffect(() => {
    getReportDraftById({ id })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) {
          const [draft] = data;
          setDraftName(draft.name);
          setReportId(draft.reportId);
        } else {
          setRedirect(true);
        }
      })
      .catch((err) => {
        onError(err);
      });
  }, []);

  useEffect(() => {
    if (!reportId) return;
    getReport();
    getReportFiles();
  }, [reportId]);

  const generateReport = () => {
    const token = Cookies.get('access_token');
    const headers = {
      authorization: token ? `Bearer ${token}` : '',
      Userid: user.id,
    };
    const par = `?source_id=${id}&source=custom_report`;
    const url = `${ttRestHost}/reports/generate${par}`;

    axios
      .get(url, { headers })
      .then((response) => {
        const { data } = response;
        // if (data?.success) {
        // }
      })
      .catch((err) => onError(err));
    setIsReportModalOpened(false);
  };

  const setStatuses = (state) => {
    const newQuestions = questionsList.map((question) => ({
      ...question,
      state,
      edited: true,
    }));
    setQuestionsList([...newQuestions]);
  };

  const saveDraftData = (filterId = null) => {
    setCustomDraftData({
      report_id: id,
      data: {
        topics: topicsList.filter((t) => t.edited),
        questions: questionsList.filter((q) => q.edited && (filterId == null || filterId === q.id)),
      },
    })
      .catch((err) => onError(err))
      .finally(() => {
        setNeedRefresh(true);
      });
  };

  const generateAnswer = () => {
    const token = Cookies.get('access_token');
    const headers = {
      authorization: token ? `Bearer ${token}` : '',
      Userid: user.id,
    };
    const par = `?source_id=${id}&source=custom_report_draft`;
    const url = `${ttRestHost}/answers/generate${par}`;

    axios
      .get(url, { headers })
      .then((response) => {
        const { data } = response;
        if (data?.success) setStatuses(statuses.PROCESSING);
        setNeedSave(true);
      })
      .catch((err) => onError(err))
      .finally(() => {
        setStatuses(statuses.PROCESSING);
        setNeedSave(true);
      });
    setIsWarningOpened(false);
  };

  useEffect(() => {
    if (needSave) saveDraftData();
  }, [needSave]);

  useEffect(() => {
    if (!reportId) return;
    if (needRefresh) {
      getReport();
      getReportFiles();
      setNeedRefresh(false);
    }
  }, [needRefresh, reportId]);

  return (
    <>
      <Modal
        onClick={(e) => e.stopPropagation()}
        open={isSoon}
        onClose={() => setIsSoon(false)}
        title="Coming soon"
      >
        <ComingSoon text="Template creating in development." />
      </Modal>
      <Modal
        open={isReportModalOpened}
        onClose={() => setIsReportModalOpened(false)}
        onClick={(e) => e.stopPropagation()}
        title="Are you sure you are ready to start generating report?"
        className={styles.warningModal}
      >
        <div>
          Please check the questions and answers before starting report generation. Only
          successfully processed data will be included in the report.
        </div>
        <div className={styles.buttonBlock}>
          <Button type="default" onClick={() => setIsReportModalOpened(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={generateReport}>
            Generate Report
          </Button>
        </div>
      </Modal>
      <Modal
        open={isWarningOpened}
        onClose={() => setIsWarningOpened(false)}
        onClick={(e) => e.stopPropagation()}
        title="Are you sure you are ready to start generating answers?"
        className={styles.warningModal}
      >
        <div>
          {isDisabled ? (
            <>
              All your questions in processing. Please wait until all questions will be processed.
            </>
          ) : (
            <>
              Upon your confirmation, the process of generating answers will begin and shall take up
              to several minutes. Once generated the answers will be saved and available for review
              and editing in the &ldquo;Drafts&rdquo; subsection of the
              &ldquo;Score&nbsp;&&nbsp;Reporting&rdquo; section of your dashboard. At any time, you
              can generate a report based on these answers by clicking &ldquo;get a report&rdquo;
              at&nbsp;the end of the page. The report in the PDF format will be available in the
              &ldquo;Document Flow&rdquo; section of your dashboard.
            </>
          )}
        </div>
        <div className={styles.buttonBlock}>
          <Button type="default" onClick={() => setIsWarningOpened(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={generateAnswer} disabled={isDisabled}>
            Generate Answers
          </Button>
        </div>
      </Modal>
      <div className={styles.contentBlock}>
        <div className={styles.blockHeader}>
          <div className={styles.nameBlock} onClick={() => navigate(URL_CUSTOM_REPORTS_DRAFTS)}>
            <p>&lt; {draftName}</p>
          </div>
          <Button type="link" onClick={() => onProceedDraft()}>
            Proceed
            <NavigateNext />
          </Button>
        </div>
        <div className={styles.buttonBlock}>
          <Button
            type="default"
            onClick={() => setIsWarningOpened(true)}
            startIcon={<GeneratingIco />}
          >
            Generate all answers
          </Button>
          <Button
            type="default"
            onClick={() => setIsReportModalOpened(true)}
            startIcon={<DocIco />}
          >
            Generate report
          </Button>
          <Button type="default" onClick={() => setIsSoon(true)} startIcon={<DocIco />}>
            Create template
          </Button>
        </div>
        {!isLoaded.files || !isLoaded.report ? (
          <div>Loading...</div>
        ) : (
          <QuestionsList params={{ report, resultsFilesList, resultsFilesCodes, statuses }} />
        )}
      </div>
    </>
  );
};

export default CustomDraftDetail;
