import Cookies from 'js-cookie';
import { openNotification } from 'tt-ui-kit';

export const openAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 1;
    loader.style.visibility = 'visible';
  }
};

export const closeAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 0;
    loader.style.visibility = 'hidden';
  }
};

export const getAvatarPathBySize = (files, pathSize) => {
  if (files && files.length) {
    if (typeof files === 'object') {
      return files[0][pathSize];
    }
    if (typeof files === 'string') {
      return files;
    }
  }
  return '';
};

export const logoutUserHandler = (logoutApiMethod, disconnectSocketMethod, withRedirect = true) => {
  openAppLoader();

  logoutApiMethod().finally(() => {
    const domainValue = process.env.REACT_APP_DOMAIN;
    Cookies.remove('access_token', { domain: domainValue });
    Cookies.remove('userId', { domain: domainValue });
    Cookies.remove('marketId', { domain: domainValue });
    Cookies.remove('companyId', { domain: domainValue });
    Cookies.remove('calculatorId', { domain: domainValue });
    Cookies.remove('last_active', {
      domain: domainValue,
    });
    localStorage.removeItem('currentUserData');
    localStorage.removeItem('currentCompanyData');
    disconnectSocketMethod();

    if (withRedirect) {
      window.location.href = '/dashboard/feed';
    } else {
      window.location.reload();
    }
  });
};

export const downloadFileFromURL = (url, name) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const setErrorMessage = (text) => {
  openNotification({
    message: text,
    type: 'error',
  });
};

export const contentEditorFocusHandler = (e) => {
  // if (
  //   e.target.closest(
  //     '.tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root',
  //   ) !== null
  // ) {
  //   e.stopImmediatePropagation();
  // }
  e.stopImmediatePropagation();
};
