import React, { useState, useEffect } from 'react';
import styles from './MessengerPage.module.scss';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Image, PersonIcon } from 'tt-ui-kit';
import { MessageList } from './MessageList';
import SearchNav from '../../components/Search';
import { AppAvatar } from '../../components/Avatar';
import { format, parseISO } from 'date-fns';
import { useWindowSize } from '../../utils/useWindowSize';
import { CircularProgress } from '@mui/material';

const MobileChat = ({
  setIsAddFolderModalOpen,
  setActiveFolderId,
  activeFolderId,
  chatFolders,
  getChatsByCategory,
  allChats,
  openChatByID,
  lastMessage,
  lastMessages,
  userChatId,
  chatReceiverID,
  fullUser,
  userChatInfo,
  handleStartDirectCall,
  newMessageSent,
  createEmptyChat,
  closeChat,
  setAllFolderChat,
  allFolderChat,
  setIsLoadingList,
  isLoadingList,
  onScrollUsers,
  getMore,
  isLoading,
  connectedUsers,
  createNewChat,
}) => {
  const { width } = useWindowSize();
  const [tabletVersion, setTabletVersion] = useState(false);
  const [openResponsiveChat, setOpenResponsiveChat] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFolder, setActiveFolder] = useState({});
  const open = Boolean(anchorEl);

  useEffect(() => {
    setAllFolderChat(chatFolders);
  }, [chatFolders]);

  useEffect(() => {
    setActiveFolder(chatFolders.filter((item) => item.id === activeFolderId));
    setAllFolderChat(chatFolders.filter((item) => item.id !== activeFolderId));
  }, [activeFolderId]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (width >= 1025) {
      setTabletVersion(true);
    } else {
      setTabletVersion(false);
    }
  }, [width]);

  return (
    <div className={styles.mobileContainer}>
      <div className={styles.tabsContainer}>
        {tabletVersion ? (
          <Tabs
            TabIndicatorProps={{ style: { backgroundColor: '#143471' } }}
            centered
            value={activeFolderId}
            onChange={(e, newValue) => {
              getChatsByCategory(newValue);
              setActiveFolderId(newValue);
            }}
            className={styles.tabs}
            scrollButtons
          >
            <button
              className={styles.tabSettings}
              type="button"
              onClick={() => setIsAddFolderModalOpen(true)}
            >
              <Image src="/images/chat/chatSettingsIcon.svg" width={24} height={22} />
            </button>
            <Tab
              value=""
              label="All chats"
              className={[styles.tabItem, activeFolderId === '' ? styles.activeItem : null]}
              sx={{ margin: '0px 30px', padding: 0 }}
            />

            {chatFolders.map((item) => (
              <Tab
                value={item.id}
                label={item.name}
                className={[styles.tabItem, activeFolderId === item.id ? styles.activeItem : null]}
                sx={{ margin: '0px 30px', padding: 0 }}
              />
            ))}
          </Tabs>
        ) : (
          <>
            <div onClick={handleClick} className={styles.tabButton}>
              <div className={styles.mobileTabTitle}>
                {activeFolder[0] ? activeFolder[0].name : 'All chats'}
              </div>
              <Image src="/images/chat/arrow.svg" width={15} height={15} />
            </div>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: '100%',
                  borderRadius: '16px',
                },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  getChatsByCategory('');
                  setActiveFolderId('');
                }}
              >
                <div className={styles.tabButtonInside}>
                  <div className={styles.mobileTabTitle}>
                    {activeFolder[0] ? activeFolder[0].name : 'All chats'}
                  </div>
                  <div style={{ transform: 'rotate(180deg)' }}>
                    <Image src="/images/chat/arrow.svg" width={15} height={15} />
                  </div>
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setIsAddFolderModalOpen(true);
                }}
              >
                <div className={styles.mobileChatSetting}>
                  <div className={styles.mobileSettingTitle}>Settings</div>
                  <Image src="/images/chat/chatSettingsIcon.svg" width={24} height={22} />
                </div>
              </MenuItem>
              {activeFolder[0] && (
                <MenuItem
                  onClick={() => {
                    setIsLoadingList(true);
                    handleClose();
                    getChatsByCategory('');
                    setActiveFolderId('');
                  }}
                >
                  <div className={styles.mobileSettingTitle}>All chats</div>
                </MenuItem>
              )}
              {allFolderChat &&
                allFolderChat.map((item) => (
                  <MenuItem
                    onClick={() => {
                      setIsLoadingList(true);
                      handleClose();
                      getChatsByCategory(item.id);
                      setActiveFolderId(item.id);
                    }}
                  >
                    <div className={styles.mobileSettingTitle}>{item.name}</div>
                  </MenuItem>
                ))}
            </Menu>
          </>
        )}
      </div>
      <div className={styles.mobileChatsContainer}>
        {userChatId || chatReceiverID ? (
          isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress
                sx={{
                  width: '40px !important',
                  height: '40px !important',
                }}
              />
            </div>
          ) : (
            <MessageList
              closeChat={closeChat}
              authUser={fullUser}
              chatId={userChatId}
              chatReceiverID={chatReceiverID}
              chatInfo={userChatInfo}
              startDirectCall={(receiver) => handleStartDirectCall(receiver)}
              messageSent={(message) => newMessageSent(message)}
              createChat={() => createEmptyChat()}
              isMobile
              allChats={allChats}
              createNewChat={createNewChat}
            />
          )
        ) : isLoadingList || isLoading ? (
          <div>
            <div className={styles.chatsLoading}>
              <CircularProgress
                sx={{
                  width: '40px !important',
                  height: '40px !important',
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.chatListUsers}>
            <SearchNav />
            <div onScroll={onScrollUsers} className={styles.chatListUsersContent}>
              {allChats.map((element) => (
                <div
                  key={element.id}
                  className={styles.userChat}
                  onClick={() =>
                    openChatByID(element.id, element.partner_id_user, element.partner_company_id)
                  }
                >
                  {element.partner_id_user ? (
                    <>
                      <div className={styles.userAvatarContent}>
                        <AppAvatar
                          className={styles.userAvatar}
                          nav={0}
                          size="xs"
                          userName={`${element.partner_id_user.first_name} 
                          ${element.partner_id_user.last_name}`}
                          src={
                            !element.partner_company_id
                              ? element.partner_id_user.avatar === 'no avatar'
                                ? null
                                : element.partner_id_user.avatarSmall
                              : element.partner_id_user.avatar
                          }
                          isOnline={connectedUsers.includes(element.partner_id_user.id)}
                        />
                      </div>
                      <div className={styles.chatInfo}>
                        <div className={styles.userChatName}>
                          {`${element.partner_id_user.first_name} 
                          ${element.partner_id_user.last_name}`}
                        </div>
                        <div className={styles.userChatMessegeAndDate}>
                          {element.partner_id_user.status === 'blocked' ? (
                            <div
                              className={`${styles.userChatMessege} ${styles.deleteUserChatMessege}`}
                            >
                              Blocked account
                            </div>
                          ) : element.partner_id_user.status === 'not_blocked' ? (
                            <div className={styles.userChatMessege}>{lastMessage(element.id)}</div>
                          ) : (
                            <div
                              className={`${styles.userChatMessege} ${styles.deleteUserChatMessege}`}
                            >
                              User deleted
                            </div>
                          )}
                        </div>
                        <div className={styles.date}>
                          {lastMessages[element.id]
                            ? format(parseISO(lastMessages[element.id].created_at), 'H:mm')
                            : ''}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.userAvatarContent}>
                        <div className={styles.iconDeleted}>
                          <PersonIcon style={{ width: 20, height: 20 }} />
                        </div>
                      </div>
                      <div className={styles.chatInfo}>
                        <div className={styles.userChatName}>Deleted account</div>
                      </div>
                    </>
                  )}
                </div>
              ))}
              {getMore && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress
                    sx={{
                      width: '40px !important',
                      height: '40px !important',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileChat;
