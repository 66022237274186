import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';
import { ReactComponent as AnsveredSvg } from '../../../../../../assets/svg/checkMark.svg';
import styles from './CarbonAnswers.module.scss';
import ResultFilesModal from '../../../CalcResultFilesModal/ResultFilesModal';
import { getQuestions } from 'api/calc/endpoints/calc';

const CustomizedAccordion = styled(Accordion)(() => ({
  '.MuiAccordionDetails-root': {
    padding: 0,
  },
  '&.MuiAccordion-root': {
    borderRadius: '16px',
    width: '100%',
    padding: '16px',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
    marginBottom: '10px',
  },
  '&:before': {
    backgroundColor: 'white',
  },
  boxShadow: 'none',
}));

const CarbonAnswers = ({ params }) => {
  const { resultFiles, answersData, factorId } = params;

  const [resultsFilesList, setResultsFilesList] = useState([]);
  const [resultsFilesCodes, setResultsFilesCodes] = useState([]);

  const createResultObject = (answers, files) => {
    // Flatten the scope array and map it to an object for easier lookup
    const scopeMap = answers.reduce((acc, scope, index) => {
      const questions = scope.categories.flatMap((category) => category.questions);
      questions.forEach((question) => {
        acc[question.id] = {
          question: question.levelOneName,
          subquestion: null,
          code: question.id,
          title: scope.name,
          files: [],
        };
      });
      return acc;
    }, {});

    // Reduce the files array to the desired format
    files.forEach((file) => {
      if (scopeMap[file.code]) {
        scopeMap[file.code].files.push(file);
      }
    });

    // Convert the scopeMap object to an array and filter out objects without files
    const resultObject = Object.values(scopeMap).filter((item) => item.files.length > 0);

    return resultObject;
  };

  useEffect(() => {
    if (!answersData.length) return;
    const filesList = createResultObject(answersData, resultFiles);
    setResultsFilesList([...filesList]);
    setResultsFilesCodes([...filesList.map((q) => q.code)]);
  }, [answersData, resultFiles]);

  return (
    <div className={styles.companyMainFrame}>
      {answersData.map((scope) => (
        <CustomizedAccordion key={scope.code}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: '0px', margin: '0px' }}
          >
            <div className={styles.summary}>
              <h3 className={styles.title}>{scope.name}</h3>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {scope.categories.map((category) => (
              <CustomizedAccordion key={category.code}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ padding: '0px', margin: '0px' }}
                >
                  <div className={styles.summary}>
                    <h3 className={styles.title}>{category.name}</h3>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {category?.questions.map((question, indx) => (
                    <div className={styles.container}>
                      <div key={`levelOneName${indx.toString()}`} className={styles.sourceBlock}>
                        <div className={styles.questionTitle}>
                          <h4>{question.levelOneName}</h4>
                        </div>
                        {question && (
                          <div className={styles.componentsContainer}>
                            {question?.answers.map((com, idx) => (
                              <div className={styles.componentBlock}>
                                <div className={styles.componentNum}>Activity {idx + 1}:</div>
                                <div className={styles.componentDetails}>
                                  {!!com.levelTwoName && (
                                    <div>
                                      <span>Activity:</span> {com.levelTwoName}
                                    </div>
                                  )}
                                  {!!com.levelThreeName && (
                                    <div>
                                      <span>Type:</span> {com.levelThreeName}
                                    </div>
                                  )}
                                  {!!com.levelFourName && (
                                    <div>
                                      <span>Unit:</span> {com.levelFourName}
                                    </div>
                                  )}
                                  {!!com.unitName && (
                                    <div>
                                      <span>Size:</span> {com.unitName}
                                    </div>
                                  )}
                                  {!!com.uomName && (
                                    <div>
                                      <span>Additional:</span> {com.uomName}
                                    </div>
                                  )}
                                  {!!com.value && (
                                    <div>
                                      <span>Data:</span> {com.value}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      {resultsFilesCodes.includes(question.id) && (
                        <div className={styles.buttonBlock}>
                          <ResultFilesModal
                            params={{
                              resultsFilesList,
                              resultsFilesCodes,
                              code: question.id,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </AccordionDetails>
              </CustomizedAccordion>
            ))}
          </AccordionDetails>
        </CustomizedAccordion>
      ))}
    </div>
  );
};

export default CarbonAnswers;
