import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Filter from '../Filter';
import { Button, ConfirmModal, Select, TrashIcon } from 'tt-ui-kit';
import { openNotification, TableDraggable } from 'tt-ui-lib/core';
import styles from '../ChatBotPage.module.scss';
import {
  changeAvailableQuestion,
  changeBlockVisibility,
  changeVisibleQuestion,
  deleteQuestion,
} from '../../../../../api/admin/endpoints/chatBot';

export const Table = ({
  loading,
  tableData,
  languages,
  queryFilter,
  setQueryFilter,
  filter,
  selectedBlock,
  allBlocks,
  setIsAddBlock,
  setIdEditQuestion,
  setOpenEditBlock,
  setIdEditBlock,
  setIdDeleteBlock,
  setNameEditBlock,
  reloadTableMethod,
  onDragEnd,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [timeoutID, setTimeoutID] = useState(null);
  const [openDeleteBlock, setOpenDeleteBlock] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [idDeleteQuestion, setIdDeleteQuestion] = useState('');
  const [loadingDeleteBlock, setLoadingDeleteBlock] = useState(false);
  const [activePageInfo, setActivePageInfo] = useState({});
  const [check, setCheck] = useState(true);

  const changeFilter = (value) => {
    setQueryFilter((current) => ({
      ...current,
      is_active: value.type === 'active' ? 1 : value.type === 'hidden' ? 0 : '',
    }));
  };

  const changeOrder = (value) => {
    if (value === 'all') {
      setQueryFilter((current) => ({ ...current, order_by: undefined }));
    } else {
      setQueryFilter((current) => ({ ...current, order_by: value }));
    }
  };

  const search = (value) => {
    setInputValue(value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const timeout = setTimeout(() => {
      setQueryFilter((current) => ({ ...current, f: value }));
    }, 2000);
    setTimeoutID(timeout);
  };

  const deleteQuestionHandler = () => {
    setLoadingDeleteBlock(true);

    deleteQuestion(idDeleteQuestion)
      .then((response) => {
        if (response.status === 200) {
          setIdDeleteQuestion('');
          setOpenDeleteBlock(false);
          reloadTableMethod();
        }
      })
      .catch((err) => {
        openNotification({
          message: err?.message || 'Error while making request',
          type: 'error',
        });
      })
      .finally(() => {
        setLoadingDeleteBlock(false);
      });
  };

  const changeVisibleHandler = (id, isActive) => {
    changeVisibleQuestion(id, isActive)
      .then((response) => {
        if (response.status === 200) {
          reloadTableMethod();
        }
      })
      .catch((err) => {
        openNotification({
          message: err?.message || 'Error while making request',
          type: 'error',
        });
      });
  };
  const changeAvailabilityHandler = (id, value) => {
    changeAvailableQuestion(id, value)
      .then((response) => {
        if (response.status === 200) {
          openNotification({
            type: 'success',
            message: 'Availability updated successfully',
          });
          reloadTableMethod();
        }
      })
      .catch((err) => {
        openNotification({
          message: err?.message || 'Error while making request',
          type: 'error',
        });
      });
  };

  useEffect(() => {
    if (allBlocks && allBlocks.length && selectedBlock) {
      setActivePageInfo(allBlocks.find((item) => item.id === selectedBlock));
    }
  }, [allBlocks, selectedBlock]);

  const containsParagraphTag = (htmlString) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.querySelectorAll('p').length > 0;
  };

  const answerContent = (item) => {
    const { text } = item;
    const hasParagraph = containsParagraphTag(text);
    return (
      <>
        {hasParagraph ? (
          <p dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <p>
            <p dangerouslySetInnerHTML={{ __html: text }} />
          </p>
        )}
      </>
    );
  };

  const changeBlockVisibilityHandler = (id, isActive) => {
    changeBlockVisibility(id, isActive)
      .then((response) => {
        if (response.status === 200) {
          const updatedActive = response.data.data.is_active;
          setActivePageInfo((current) => ({ ...current, is_active: updatedActive }));
          openNotification({
            type: 'success',
            message: 'Visibility changed successfully',
          });
        }
      })
      .catch((err) => {
        openNotification({
          message: err?.message || 'Error while making request',
          type: 'error',
        });
      });
  };

  const columns = [
    {
      title: 'Section name',
      dataIndex: 'documentation_category_name',
      width: 180,
    },
    {
      dataIndex: 'note',
      title: 'Notification',
      width: 180,
    },
    {
      dataIndex: 'title',
      title: 'Question',
      width: 390,
      render: (_, value) => (
        // <Tooltip placement="left-start" title={value.value}>
        <span className={styles.question}>{value.title}</span>
        // </Tooltip>
      ),
    },
    {
      dataIndex: 'sections',
      title: 'Answer',
      width: 390,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      render: (_, value) => (
        <div className={styles.answerItems}>
          {value?.sections?.map((item) => (
            <div key={item.id}>
              {answerContent(item)}
              {item.files && item.files?.length > 0 && (
                <div className={styles.answerFiles}>
                  {item.files.map((file) => (
                    <a href={file.file}>{file.original_name}</a>
                  ))}
                </div>
              )}

              {item.links && item.links?.length > 0 && (
                <div className={styles.answerLinks}>
                  {item.links.map((link) => (
                    <a>{link.url}</a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ),
    },
    {
      dataIndex: 'display',
      title: 'Display',
      width: 356,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      render: (_, value) => {
        const chatbotValue = value.available?.find((item) => item.type === 'chatbot');
        const qaValue = value.available?.find((item) => item.type === 'qa');
        return (
          <div className={styles.checkboxBlock}>
            <FormControlLabel
              control={
                <input
                  className={styles.checkboxInput}
                  type="checkbox"
                  key={qaValue}
                  checked={!!qaValue}
                  onChange={(e) => {
                    setCheck(!check);
                    if (!e.target.checked) {
                      // eslint-disable-next-line no-param-reassign
                      value.available = value.available.filter((item) => item.type !== 'qa');
                    } else {
                      // eslint-disable-next-line no-unused-expressions
                      value.available
                        ? value.available.push({
                            type: 'qa',
                          })
                        : // eslint-disable-next-line no-param-reassign
                          (value.available = [
                            {
                              type: 'qa',
                            },
                          ]);
                    }
                    return changeAvailabilityHandler(value.id, {
                      id: qaValue?.id || null,
                      type: 'qa',
                      value: e.target.checked,
                    });
                  }}
                />
              }
              label={<Typography className={styles.checkboxLabel}>Display in Q&A</Typography>}
            />
            <FormControlLabel
              control={
                <input
                  className={styles.checkboxInput}
                  type="checkbox"
                  key={chatbotValue}
                  checked={!!chatbotValue}
                  onChange={(e) => {
                    setCheck(!check);
                    if (!e.target.checked) {
                      // eslint-disable-next-line no-param-reassign
                      value.available = value?.available?.filter((item) => item.type !== 'chatbot');
                    } else {
                      // eslint-disable-next-line no-unused-expressions
                      value.available
                        ? value.available.push({
                            type: 'chatbot',
                          })
                        : // eslint-disable-next-line no-param-reassign
                          (value.available = [
                            {
                              type: 'chatbot',
                            },
                          ]);
                    }
                    return changeAvailabilityHandler(value.id, {
                      id: chatbotValue?.id || null,
                      type: 'chatbot',
                      value: e.target.checked,
                    });
                  }}
                />
              }
              label={
                <Typography className={styles.checkboxLabel}>Display in the Сhatbot</Typography>
              }
            />
          </div>
        );
      },
    },
    {
      dataIndex: 'actions',
      title: ' ',
      width: 110,
      render: (_, value) => (
        <div className={styles.answersColumn}>
          {/* TODO: language selector */}
          <Select
            type="clear"
            items={languages}
            value="en"
            className={styles.actionsLanguageSelect}
          />
          <IconButton
            onClick={() => {
              setIdEditQuestion(value.id);
              setIsAddBlock(true);
            }}
          >
            <img src="/images/admin/tableEditIcon.svg" alt="" />
          </IconButton>
          <IconButton
            onClick={() => {
              setIdDeleteQuestion(value.id);
              setOpenDeleteBlock(true);
            }}
          >
            <img src="/images/admin/tableDeleteIcon.svg" alt="" />
          </IconButton>
          <IconButton
            onClick={() => {
              changeVisibleHandler(value.id, value?.row?.is_active === 1 ? 0 : 1);
            }}
          >
            <img
              src={
                value?.row?.is_active === 1
                  ? '/images/admin/tableVisIcon.svg'
                  : '/images/admin/tableInvisibleIcon.svg'
              }
              alt=""
            />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Box className={styles.tableContent}>
      {selectedBlock && selectedBlock !== 'all' ? (
        <Box className={styles.tableTitle}>
          <p>{allBlocks.find((item) => item.id === selectedBlock)?.name}</p>
          <div className={styles.editActions}>
            <Button
              type="default"
              className={styles.defaultButton}
              onClick={() => {
                setOpenEditBlock(true);
                setIdEditBlock(selectedBlock);
                setNameEditBlock(allBlocks.find((item) => item.id === selectedBlock)?.name);
              }}
            >
              <>
                <img src="/images/admin/edit.svg" alt="" />
                <Typography className={styles.text}>Edit Name</Typography>
              </>
            </Button>
            <Button
              type="default"
              className={styles.defaultButton}
              onClick={() =>
                changeBlockVisibilityHandler(activePageInfo?.id, !activePageInfo.is_active)
              }
            >
              {activePageInfo?.is_active ? (
                <>
                  <RemoveRedEyeOutlinedIcon style={{ width: 24, height: 24 }} />
                  <Typography className={styles.text}>Hide block</Typography>
                </>
              ) : (
                <>
                  <VisibilityOffIcon style={{ width: 24, height: 24 }} />
                  <Typography className={styles.text}>Open block</Typography>
                </>
              )}
            </Button>
            <Button
              type="default"
              className={styles.defaultButton}
              onClick={() => {
                setIdDeleteBlock(selectedBlock);
              }}
            >
              <TrashIcon />
              <Typography className={styles.text}>Delete</Typography>
            </Button>
          </div>
          <div className={styles.editActionsMobile}>
            <Button
              type="link"
              className={styles.defaultButton}
              onClick={() => {
                setOpenEditBlock(true);
                setIdEditBlock(selectedBlock);
                setNameEditBlock(allBlocks.find((item) => item.id === selectedBlock)?.name);
              }}
              disableRipple
            >
              <>
                <img src="/images/admin/edit.svg" alt="" />
              </>
            </Button>
            <Button
              type="link"
              className={styles.defaultButton}
              onClick={() => setIsVisible(!isVisible)}
              disableRipple
            >
              {isVisible ? (
                <>
                  <RemoveRedEyeOutlinedIcon style={{ width: 24, height: 24 }} />
                </>
              ) : (
                <>
                  <VisibilityOffIcon style={{ width: 24, height: 24 }} />
                </>
              )}
            </Button>
            <Button
              type="link"
              className={styles.defaultButton}
              disableRipple
              onClick={() => {
                setIdDeleteBlock(selectedBlock);
              }}
            >
              <TrashIcon />
            </Button>
          </div>
        </Box>
      ) : null}
      {selectedBlock ? (
        <Filter
          filter={filter}
          inputValue={inputValue}
          changeFilter={changeFilter}
          orderBy={queryFilter.order_by}
          changeOrder={changeOrder}
          search={search}
          setIsAddBlock={setIsAddBlock}
        />
      ) : null}

      <Box sx={{ marginTop: '20px' }}>
        <TableDraggable
          columns={columns}
          rows={tableData}
          loading={loading}
          isCentered={false}
          onDragEnd={onDragEnd}
        />
      </Box>

      <ConfirmModal
        open={openDeleteBlock}
        setOpen={setOpenDeleteBlock}
        loading={loadingDeleteBlock}
        title="Delete question"
        subTitle="Do you really want to delete this question?"
        onSubmit={() => {
          deleteQuestionHandler();
        }}
      />
    </Box>
  );
};
