/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Image, HeaderLoginIcon, HeaderMenuIcon, CloseCrossIcon } from 'tt-ui-kit';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import styles from './Header.module.scss';
import HeaderNavItem from './HeaderNavItem';
import HeaderRolesMenu from './HeaderRolesMenu';
import { ROLES_LIST } from '../../../constants/joinPage';
import Cookies from 'js-cookie';
import { useSelectedContext } from 'context/contextSelector';

const Header = ({
  isHeaderNav,
  isLogoHidden,
  isRolePage,
  isSignUpPage,
  isSignInPage,
  isSdgPage,
  homePage,
}) => {
  const location = useLocation();
  const [selectedRote, setSelectedRote] = useState(null);
  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const { isError } = useSelectedContext((context) => context.app);
  const idUser = Cookies.get('userId');

  const onClose = (router, isSdg) => {
    if (isSdg) {
      const routeArr = location.pathname.split('/');
      if (routeArr.length > 2) {
        router('/sdg');
      } else {
        router('/menu');
      }
    } else {
      router('/join');
    }
  };

  useEffect(() => {
    if (location && (isRolePage || isSignUpPage)) {
      const roleUrl = location.pathname.split('/')[2];
      const roteFromList = ROLES_LIST.find((role) => role.url === roleUrl);
      setSelectedRote(roteFromList);
    }
  }, [location, isRolePage, isSignUpPage]);

  useEffect(() => {
    if (isSignUpPage || isSignInPage || isSdgPage) {
      const container = document.querySelector('#imageBg');
      if (container) {
        const handler = (e) => {
          setIsPageScrolled((isScrolled) => {
            if (
              !isScrolled &&
              (e.target.scrollTop > 20 || document.documentElement.scrollTop > 20)
            ) {
              return true;
            }

            if (isScrolled && e.target.scrollTop < 4 && document.documentElement.scrollTop < 4) {
              return false;
            }

            return isScrolled;
          });
        };

        container.addEventListener('scroll', handler);
        return () => container.removeEventListener('scroll', handler);
      }
    }
  }, [isSignUpPage]);

  return (
    <header
      className={clsx(
        styles.headerContainer,
        isLogoHidden ? styles.hidden : '',
        isSignUpPage || isSignInPage || isSdgPage ? styles.signHeader : '',
        isPageScrolled ? styles.scrolled : '',
        isError ? styles.withoutZIndex : ''
      )}
    >
      <div className={styles.logo}>
        <Link to="/">
          <Image
            src="/images/templates/logo-transparenterra-big.svg"
            layout="fill"
            title=""
            className={styles.logoImg}
          />
        </Link>
      </div>
      {(isRolePage || isSignUpPage) && selectedRote && (
        <HeaderRolesMenu
          roles={ROLES_LIST}
          selectedRole={selectedRote}
          isSignUpPage={isSignUpPage}
        />
      )}
      {isHeaderNav && (
        <nav className={styles.headerNav}>
          {idUser ? (
            <HeaderNavItem
              href="/dashboard/feed"
              icon={<HeaderLoginIcon style={{ width: 20, height: 18 }} />}
              text="Home"
            />
          ) : (
            <HeaderNavItem
              href="/sign_in"
              icon={<HeaderLoginIcon style={{ width: 20, height: 18 }} />}
              text="Sign In/Up"
            />
          )}
          {/* <HeaderNavItem
            href="/menu"
            icon={<HeaderMenuIcon style={{ width: 24, height: 24 }} />}
            text="MENU"
          /> */}
        </nav>
      )}
      {(isSdgPage || homePage) && (
        <nav className={styles.headerNav}>
          {/* <HeaderNavItem
            href="/menu"
            icon={<HeaderMenuIcon style={{ width: 24, height: 24 }} />}
            text="MENU"
          /> */}
        </nav>
      )}
      {isSignInPage && (
        <nav className={styles.headerNav}>
          <HeaderNavItem
            imageSrc="/images/templates/close-icon.svg"
            icon={
              <CloseCrossIcon
                style={{ width: 22, height: 22, filter: 'brightness(0) invert(1)' }}
              />
            }
            text="CLOSE"
            onClose={(router) => onClose(router, isSdgPage)}
          />
        </nav>
      )}
    </header>
  );
};

export default Header;
