import * as React from 'react';
import { DataGrid, GridColumnMenu } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { v1 as uuidv1 } from 'uuid';
import styles from './DataTable.module.scss';
import Box from '@mui/material/Box';
import { Typography } from 'tt-ui-kit';

function customCheckbox(theme) {
  return {
    '& .MuiCheckbox-root svg': {
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'}`,
      borderRadius: 2,
    },
    '& .MuiCheckbox-root svg path': {
      display: 'none',
    },
    '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
      backgroundColor: '#1890ff',
      borderColor: '#1890ff',
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
      position: 'absolute',
      display: 'table',
      border: '2px solid #fff',
      borderTop: 0,
      borderLeft: 0,
      transform: 'rotate(45deg) translate(-50%,-50%)',
      opacity: 1,
      transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
      content: '""',
      top: '50%',
      left: '39%',
      width: 5.71428571,
      height: 9.14285714,
    },
    '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
      width: 8,
      height: 8,
      backgroundColor: '#1890ff',
      transform: 'none',
      top: '39%',
      border: 0,
    },
  };
}
const CustomColumnMenu = (props) => (
  <GridColumnMenu
    {...props}
    slots={{
      columnMenuColumnsItem: null,
    }}
  />
);
const DataTable = ({
  columns,
  rows,
  currentPage,
  pageCount,
  changePage,
  pageSize,
  loading,
  maxWidth = '100%',
  isCentered = true,
}) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomPagination = () =>
    pageCount > 1 && (
      <Box>
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          page={currentPage}
          siblingCount={1}
          count={pageCount}
          className={styles.pagination}
          // @ts-expect-error
          renderItem={(props) => (
            <PaginationItem className={styles.paginationItem} {...props} disableRipple />
          )}
          onChange={(event, value) => {
            changePage(event, value);
          }}
        />
        <Typography
          className={styles.paginationInfo}
        >{`Page ${currentPage} of ${pageCount}`}</Typography>
      </Box>
    );

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Montserrat',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
      justifyContent: 'center',
      borderTop: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
      fontFamily: 'Montserrat',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    },
    '& .MuiDataGrid-cell': {
      color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
      padding: '12px 20px',
      fontSize: 16,
      alignItems: isCentered ? 'center' : 'flex-start !important',
      justifyContent: isCentered ? 'center' : 'flex-start !important',
    },
    '& .MuiDataGrid-cellContent': {
      fontFamily: 'Montserrat',
      fontWeight: 400,
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell:last-child': {
      borderRight: `none`,
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
    '& .Mui-selected': {
      color: '#212121',
      borderColor: '#143471 !important',
      background: '#ffffff',
    },
    ...customCheckbox(theme),
  }));

  return (
    <div style={{ height: '70vh', width: '100%', maxWidth: maxWidth }}>
      <StyledDataGrid
        loading={loading}
        pageSize={pageSize}
        hideFooterSelectedRowCount
        slots={{
          pagination: CustomPagination,
          columnMenu: CustomColumnMenu,
        }}
        getRowId={(row) => row.id || uuidv1()}
        rows={rows}
        getRowHeight={(params) => {
          if (params.model?.roles?.length > 1) {
            return 'auto';
          }

          if (params.model?.sections) {
            return 'auto';
          }

          return null;
        }}
        paginationMode="server"
        columns={columns}
      />
    </div>
  );
};

export default DataTable;
