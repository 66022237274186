import { Loader } from 'components/Loader';
import React from 'react';
import styles from '../../NewPostModal.module.scss';
import { VideoPreview } from './VideoPreview';
import { CloseCrossIcon, FileIcon, ReloadIcon } from 'tt-ui-lib/icons';

export const FilePreview = ({
  selectedFiles,
  handleRemoveFile,
  handleFileUpload,
  updateFileMethod,
  imageFullWidth = false,
  isEdit = false,
}) => {
  const handleRemoveFileWithPrev = (fileId, uuid, url, isNew = true) => {
    if (isEdit) {
      handleRemoveFile(fileId, uuid, url, !isNew);
    } else {
      handleRemoveFile(fileId, uuid, url);
    }
  };

  const reuploadFile = (item) => {
    handleFileUpload(item);
  };

  return isEdit ? (
    <div className={styles.previewContainer}>
      {selectedFiles
        .filter((item) => item.type === 'video')
        .map(
          (videoPreview, index) =>
            !videoPreview.to_delete && (
              <VideoPreview
                file={videoPreview}
                key={videoPreview.id}
                handleRemoveFileWithPrev={handleRemoveFileWithPrev}
                updateFileMethod={updateFileMethod}
                isEdit
              />
            )
        )}

      {selectedFiles
        .filter((item) => item.type === 'image')
        .map(
          (imagePreview, index) =>
            !imagePreview.to_delete && (
              <div
                key={imagePreview.id}
                className={imageFullWidth ? styles.uploadImgBoxFull : styles.uploadImgBox}
              >
                <div
                  className={styles.uploadImgBoxLoader}
                  style={{ display: imagePreview.loading ? 'flex' : 'none' }}
                >
                  <div className={styles.uploadImgBoxLoaderProgressBar}>
                    <div className={styles.uploadImgBoxLoaderProgress} />
                  </div>
                </div>

                <div
                  className={styles.uploadImgBoxLoader}
                  style={{ display: imagePreview.error ? 'flex' : 'none', border: '1px solid red' }}
                >
                  <div
                    onClick={() => {
                      reuploadFile(imagePreview);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <ReloadIcon style={{ width: 24, height: 24 }} className="icon-white-fill" />
                  </div>
                </div>

                <img
                  src={imagePreview.path || imagePreview.url || imagePreview.preview}
                  alt=""
                  className={imagePreview.loading || imagePreview.error ? 'blur-content' : ''}
                />
                <button
                  type="button"
                  className={styles.uploadImgClose}
                  style={{
                    display: imagePreview.loading ? 'none' : 'flex',
                    zIndex: imagePreview.error ? 2000 : 'unset',
                  }}
                  onClick={() => {
                    handleRemoveFileWithPrev(
                      imagePreview.id,
                      imagePreview.uuid || imagePreview.id,
                      imagePreview.url || imagePreview.path,
                      !!imagePreview.uuid
                    );
                  }}
                >
                  <CloseCrossIcon style={{ width: 16, height: 16 }} className="icon-white-fill" />
                </button>
              </div>
            )
        )}

      <br />
      {selectedFiles.map((file, index) => {
        const fileType = file.type;

        if (fileType === 'video' || fileType === 'image' || file.to_delete) {
          return null;
        }

        // if (fileType === 'audio') {

        // }

        return (
          <div key={file.file.name} className={styles.uploadFileBox}>
            <div className={styles.uploadFileTitle}>
              {file.loading ? (
                <Loader />
              ) : file.error ? (
                <div
                  onClick={() => {
                    reuploadFile(file);
                  }}
                >
                  <ReloadIcon style={{ width: 20, height: 20 }} className="icon-red-fill" />
                </div>
              ) : (
                <FileIcon style={{ width: 20, height: 20 }} className="icon-green-fill" />
              )}
              <span style={{ color: file.error ? '#D32F2F' : '#000', marginLeft: 10 }}>
                {file.file.name || file.original_name}
              </span>
            </div>
            <span
              className={styles.uploadFileBoxClose}
              style={{ opacity: file.loading ? 0.5 : 1 }}
              onClick={() =>
                !file.loading &&
                handleRemoveFileWithPrev(
                  file.id,
                  file.uuid || file.id,
                  file.url || file.path,
                  !!file.uuid
                )
              }
            >
              <CloseCrossIcon style={{ width: 20, height: 20 }} />
            </span>
          </div>
        );
      })}
    </div>
  ) : (
    <div className={styles.previewContainer}>
      {selectedFiles
        .filter((item) => item.file.type.split('/')[0] === 'video')
        .map(
          (videoPreview, index) =>
            !videoPreview.to_delete && (
              <VideoPreview
                file={videoPreview}
                key={videoPreview.id}
                handleRemoveFileWithPrev={handleRemoveFileWithPrev}
                updateFileMethod={updateFileMethod}
              />
            )
        )}

      {selectedFiles
        .filter((item) => item.file.type.split('/')[0] === 'image')
        .map(
          (imagePreview, index) =>
            !imagePreview.to_delete && (
              <div
                key={imagePreview.id}
                className={imageFullWidth ? styles.uploadImgBoxFull : styles.uploadImgBox}
              >
                <div
                  className={styles.uploadImgBoxLoader}
                  style={{ display: imagePreview.loading ? 'flex' : 'none' }}
                >
                  <div className={styles.uploadImgBoxLoaderProgressBar}>
                    <div className={styles.uploadImgBoxLoaderProgress} />
                  </div>
                </div>

                <div
                  className={styles.uploadImgBoxLoader}
                  style={{ display: imagePreview.error ? 'flex' : 'none', border: '1px solid red' }}
                >
                  <div
                    onClick={() => {
                      reuploadFile(imagePreview);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <ReloadIcon style={{ width: 24, height: 24 }} className="icon-white-fill" />
                  </div>
                </div>

                <img
                  src={imagePreview.url || imagePreview.preview}
                  alt=""
                  className={imagePreview.loading || imagePreview.error ? 'blur-content' : ''}
                />
                <button
                  type="button"
                  className={styles.uploadImgClose}
                  style={{
                    display: imagePreview.loading ? 'none' : 'flex',
                    zIndex: imagePreview.error ? 2000 : 'unset',
                  }}
                  onClick={() => {
                    handleRemoveFileWithPrev(imagePreview.id, imagePreview.uuid, imagePreview.url);
                  }}
                >
                  <CloseCrossIcon style={{ width: 16, height: 16 }} className="icon-white-fill" />
                </button>
              </div>
            )
        )}

      <br />
      {selectedFiles.map((file, index) => {
        const fileType = file?.file?.type?.split('/')[0];

        if (fileType === 'video' || fileType === 'image' || file.to_delete) {
          return null;
        }

        // if (fileType === 'audio') {

        // }

        return (
          <div key={file.file.name} className={styles.uploadFileBox}>
            <div className={styles.uploadFileTitle}>
              {file.loading ? (
                <Loader />
              ) : file.error ? (
                <div
                  onClick={() => {
                    reuploadFile(file);
                  }}
                >
                  <ReloadIcon style={{ width: 20, height: 20 }} className="icon-red-fill" />
                </div>
              ) : (
                <FileIcon style={{ width: 20, height: 20 }} className="icon-green-fill" />
              )}
              <span style={{ color: file.error ? '#D32F2F' : '#000', marginLeft: 10 }}>
                {file.file.name}
              </span>
            </div>
            <span
              className={styles.uploadFileBoxClose}
              style={{ opacity: file.loading ? 0.5 : 1 }}
              onClick={() =>
                !file.loading && handleRemoveFileWithPrev(file.id, file.uuid, file.url)
              }
            >
              <CloseCrossIcon style={{ width: 20, height: 20 }} />
            </span>
          </div>
        );
      })}
    </div>
  );
};
