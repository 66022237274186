/* eslint-disable no-debugger */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
/* eslint-disable react/no-children-prop */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { AppAvatar } from 'components/Avatar';
import moment from 'moment';
import { ButtonBase } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_NOTIFICATIONS, MARK_AS_READ_NOTIFICATION, DELETE_NOTIFICATION } from 'api';
import styles from './NotificationPage.module.scss';
import ActionMenu from './ActionMenu';
import { useSelectedContext } from '../../context/contextSelector';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
const NotificationPage = () => {
  const timezone = dayjs.tz.guess();
  const [filterNotificationType, setFilterNotificationType] = useState('all');
  const [loading, setLoading] = useState(false);
  const { notifications, setNotifications } = useSelectedContext((context) => context.notification);
  const [getNotifications] = useLazyQuery(GET_NOTIFICATIONS);
  const [markAsRead] = useMutation(MARK_AS_READ_NOTIFICATION);
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION);

  const filterNotification = (type) => {
    setFilterNotificationType(type);
  };

  const markAsReadNotification = async (notificationId) => {
    const res = await markAsRead({
      variables: { id: notificationId },
    });
  };
  const removeNotification = async (notificationId) => {
    const res = await deleteNotification({
      variables: { id: notificationId },
    });
    if (res.data && res.data.deleteNotification) {
      const filterNotifications = notifications.filter(
        (notification) => notification.id !== notificationId
      );
      setNotifications(filterNotifications);
    }
  };

  const getUserNotifications = async () => {
    setLoading(true);
    const allNotifications = await getNotifications({
      variables: {
        input: {
          filter: filterNotificationType,
          page: 1,
          limit: 10,
        },
      },
      fetchPolicy: 'network-only',
    });
    if (
      allNotifications.data &&
      allNotifications.data.notifications &&
      allNotifications.data.notifications.data
    ) {
      setNotifications(allNotifications.data.notifications.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserNotifications();
  }, [filterNotificationType]);

  const markNotificationRead = (id) => {
    if (filterNotificationType === 'unread') {
      markAsReadNotification(id);
      const filterUnreads = notifications.filter((notification) => notification.id !== id);
      setNotifications(filterUnreads);
    } else {
      markAsReadNotification(id);
    }
  };
  const getTime = (createdAt) => {
    const localTime = dayjs.utc(createdAt).tz(dayjs.tz.guess());
    const timeFromNow = dayjs().to(localTime, true);

    let time;
    if (timeFromNow === 'a few seconds') {
      time = 'just now';
    } else if (timeFromNow === 'a minute') {
      time = '1 min';
    } else {
      const timeParts = timeFromNow.split(' ');
      time = `${timeParts[0]} ${timeParts[1][0]}.`;
    }

    return time;
  };

  return (
    <>
      <Box className={styles.buttonGroup}>
        <ButtonBase
          className={`${styles.button} ${filterNotificationType === 'all' && styles.activeButton}`}
          onClick={() => {
            filterNotification('all');
            getUserNotifications();
          }}
        >
          All
        </ButtonBase>
        <ButtonBase
          className={`${styles.button} ${
            filterNotificationType === 'unread' && styles.activeButton
          }`}
          onClick={() => {
            filterNotification('unread');
            getUserNotifications('unread');
          }}
        >
          Unread
        </ButtonBase>
      </Box>
      {!loading && notifications.length > 0 && (
        <Box className={styles.notificationContent}>
          {notifications.map((item) => (
            <Box key={item.id} className={styles.notificationItem}>
              <AppAvatar
                isOnline={item.online}
                nav={0}
                userName={
                  item.user ? `${item.user.first_name} ${item.user.last_name}` : `Notification`
                }
                src={item.user?.avatar}
                size="xs"
              />
              <Box className={styles.notificationInfo}>
                {item.title && <Box className={styles.notificationTitle}>{item.title}</Box>}
                <Box
                  className={styles.notificationDescription}
                  dangerouslySetInnerHTML={{ __html: `${item?.content?.body}` }}
                />
              </Box>

              <Box className={styles.dataBlock}>
                <Box className={styles.dateArea}>{getTime(item.created_at)}</Box>
                <ActionMenu
                  markNotificationRead={() => markNotificationRead(item.id)}
                  removeNotification={() => removeNotification(item.id)}
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {!loading && !notifications.length && (
        <Box className={styles.emptyList}>You don&apos;t have notifications yet.</Box>
      )}
      {loading && <CircularProgress sx={{ marginTop: '20px', color: 'black' }} />}
    </>
  );
};

export default NotificationPage;
