import { API_BASE_API_SSO_URL } from '../urls';

export const API_BASE_CALC_API_URL = `${API_BASE_API_SSO_URL}/tt-calculator/calculator`;

// questions
export const API_CALC_GET_QUESTIONS = `${API_BASE_CALC_API_URL}/questions/get`;
// draft
export const API_DRAFT_URL = `${API_BASE_CALC_API_URL}/draft`;
export const API_DRAFT_BY_ID_GET_URL = `${API_DRAFT_URL}/get`;
export const API_DRAFT_DELETE_URL = `${API_DRAFT_URL}/del`;
export const API_CALC_PRELIMINARY = `${API_DRAFT_URL}/preliminary`;
// drafts
export const API_DRAFTS_URL = `${API_BASE_CALC_API_URL}/drafts`;
export const API_DRAFTS_LIST = `${API_DRAFTS_URL}/get`;

// files
export const API_FILES_URL = `${API_BASE_API_SSO_URL}/tt-calculator/files`;
export const API_FILES_GET_URL = `${API_FILES_URL}/get`;
export const API_FILES_SET_URL = `${API_FILES_URL}/set`;
export const API_FILES_ADD_URL = `${API_FILES_URL}/add`;
export const API_FILES_DELETE_URL = `${API_FILES_URL}/del`;

// results
export const API_RESULTS_GET_URL = `${API_BASE_CALC_API_URL}/results/get`;
export const API_RESULT_GET_URL = `${API_BASE_CALC_API_URL}/result/get`;
export const API_RESULT_ANSWER_GET_URL = `${API_BASE_CALC_API_URL}/result/detail`;
