import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useLazyQuery } from '@apollo/client';

import { CAN_PAY_DRAFT } from '../../../../api';
import { CALCS_PER_PAGE, CALC_NAMES, CALC_PAGE_TYPES, CALC_TYPES } from 'utils/calculatorUtils';

import { LoaderOn } from 'tt-ui-lib/core';
import CalcAccordion from '../../components/CalcAccordion/CalcAccordion';
import CalcIcon from '../../components/CalcIcon/CalcIcon';
import CalcFilter from '../../components/CalcFilter/CalcFilter';
import CalcDraft from '../../components/CalcDraft/CalcDraft';
import ReportDraft from '../../components/ByType/CustomReport/DraftListElement/ReportDraft';
import CalcEmptyListBlock from '../../components/CalcEmptyListBlock/CalcEmptyListBlock';
import { Alert, Snackbar } from '@mui/material';
import { getReportDraftWithFilter } from 'api/reports/endpoints/reports';
import { getCalcDrafts } from 'api/calc/endpoints/calc';

const DraftsList = ({ targetWidth, pageType }) => {
  const types = CALCS_PER_PAGE[pageType];
  const { pathname } = useLocation();

  const [searchedDrafts, setSearchedDrafts] = useState([]);
  const [isPayChecked, setIsPayChecked] = useState(false);
  const [drafts, setDrafts] = useState([]);
  const [canPayDraft] = useLazyQuery(CAN_PAY_DRAFT);
  const [needReload, setNeedReload] = useState(false);

  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const onErrorClose = () => {
    setErrorText(null);
  };

  const RenewReportDraftsList = () => {
    getReportDraftWithFilter({ pageType })
      .then((res) => {
        if (res.data.success) {
          const { data } = res.data;
          setDrafts(data);
          setIsPayChecked(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setNeedReload(false));
  };

  const RenewCalcDraftsList = () => {
    getCalcDrafts()
      .then((res) => {
        const { data, success } = res.data;
        if (success) {
          setDrafts(data);
          setIsPayChecked(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setNeedReload(false));
  };

  useEffect(() => {
    if (!needReload) return;
    if (pageType !== CALC_PAGE_TYPES.BENCHMARK) RenewReportDraftsList();
    else RenewCalcDraftsList();
  }, [needReload]);

  useEffect(() => {
    if (pageType !== CALC_PAGE_TYPES.BENCHMARK) RenewReportDraftsList();
    else RenewCalcDraftsList();
  }, [pathname]);

  useEffect(() => {
    if (isPayChecked || !drafts.length) return;
    canPayDraft({
      variables: { input: { calculatorIds: [...drafts.map((d) => d.id)] } },
    })
      .then((res) => {
        const { data } = res;
        const canPayList = data?.getCalculatorsPayAccess;
        if (!canPayList) return;
        const newDrafts = drafts.map((d) => ({
          ...d,
          canPay: canPayList.find((p) => p.id === d.id)?.canPay ?? true,
        }));
        setDrafts([...newDrafts]);
        setIsPayChecked(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [drafts]);

  return (
    <>
      {drafts.length > 0 ? (
        <>
          <CalcFilter setSearchedItems={setSearchedDrafts} items={drafts} />
          {types.map((type) => (
            <>
              {searchedDrafts.some((d) => d.type === type) && (
                <div key={type} style={{ position: 'relative' }}>
                  <CalcAccordion logo={<CalcIcon type={type} />} title={CALC_NAMES[type]}>
                    {searchedDrafts
                      .filter((draft) => draft.type === type)
                      .map((draft) => (
                        <div key={draft.id}>
                          {pageType === CALC_PAGE_TYPES.CUSTOM ? (
                            <ReportDraft
                              targetWidth={targetWidth}
                              draft={draft}
                              refresh={() => setNeedReload(true)}
                              setErrorText={setErrorText}
                            />
                          ) : (
                            <CalcDraft
                              targetWidth={targetWidth}
                              draft={draft}
                              refresh={() => setNeedReload(true)}
                              setErrorText={setErrorText}
                            />
                          )}
                        </div>
                      ))}
                  </CalcAccordion>
                  {needReload && <LoaderOn />}
                </div>
              )}
            </>
          ))}
        </>
      ) : (
        <CalcEmptyListBlock collectionName="drafts" />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!errorText}
        onClose={onErrorClose}
      >
        <Alert
          onClose={onErrorClose}
          severity="error"
          sx={{ width: '100%', backgroundColor: '#D32F2F', color: '#ffffff' }}
        >
          {errorText ?? 'Something went wrong, try again'}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DraftsList;
