import AppState from './App/AppState';
import AppContext from './App/appContext';
import messengerContext from './Messenger/messengerContext';
import userContext from './User/userContext';
import notificationContext from './Notification/notificationContext';
import MainContextProvider from './MainContextProvider';

export const allContexts = {
  app: AppContext,
  user: userContext,
  messenger: messengerContext,
  notification: notificationContext,
};

export { AppState, MainContextProvider, AppContext };
