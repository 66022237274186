/* eslint-disable no-unused-vars */
import React from 'react';
import { HeaderLoginIcon, HeaderMenuIcon, Image } from 'tt-ui-kit';
import { Hidden } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './TitledBackgroundHeader.module.scss';
import HeaderNavItem from '../Header/HeaderNavItem';
import Cookies from 'js-cookie';

const TitledBackgroundHeader = ({ title }) => {
  const idUser = Cookies.get('userId');

  return (
    <div>
      <header className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          <div className={styles.logo}>
            <Link to="/">
              <Image
                src="/images/templates/logo-transparenterra-text-big.svg"
                layout="fill"
                title=""
                className={styles.logoImg}
              />
            </Link>
          </div>
        </div>
        <nav className={styles.headerNav}>
          {idUser ? (
            <HeaderNavItem
              className={styles.navItem}
              href="/dashboard/feed"
              icon={<HeaderLoginIcon style={{ width: 20, height: 18 }} />}
              text="Home"
            />
          ) : (
            <HeaderNavItem
              className={styles.navItem}
              href="/sign_in"
              icon={<HeaderLoginIcon style={{ width: 20, height: 18 }} />}
              text="Sign In/Up"
            />
          )}
          {/* <HeaderNavItem
            className={styles.navItem}
            href="/menu"
            icon={<HeaderMenuIcon style={{ width: 24, height: 24 }} />}
            text="MENU"
          /> */}
        </nav>
      </header>
      <div className={styles.titleBackground}>
        <p className={styles.title}>
          {title?.charAt(0)}
          <span className={styles.restOfTitle}>{title?.slice(1)}</span>
        </p>
      </div>
    </div>
  );
};

export default TitledBackgroundHeader;
