export const filterNulls = (data) => {
  const keys = Object.keys(data).filter((key) => data[key] !== null);
  return keys.reduce((res, key) => ({ ...res, [key]: data[key] }), {});
};

/**
 * рекурсивная функция для изменения свойств обьектов в camelCase
 * @param {*} obj - обект со свойствами в виде snake_case
 * @returns - объект со свойствами в виде camelCase
 */
export const snakeToCamel = (obj) => {
  const newObj = Object.keys(obj).reduce(
    (res, prop) => {
      const newPropName = prop.replaceAll(
        /._./g,
        (match) => `${match.split('_')[0]}${match.split('_')[1].toUpperCase()}`
      );
      const newPropValue =
        obj[prop] && typeof obj[prop] === 'object' ? snakeToCamel(obj[prop]) : obj[prop];
      return Array.isArray(obj) ? [...res, newPropValue] : { ...res, [newPropName]: newPropValue };
    },
    Array.isArray(obj) ? [] : {}
  );
  return newObj;
};

export const CALC_TYPES = {
  CARBON: 'carbon',
  LOCAL: 'local',
  GLOBAL: 'global',
  COMPANY: 'company',
  PROJECT: 'project',
  ECOVADIS: 'ecovadis',
  CUSTOM_REPORT: 'custom_report',
  CUSTOM_FILES: 'custom_files',
  SUST_CSRD_ESRD: 'sust_csrd_esrd',
  SUST_GRI: 'sust_gri',
  SUST_ISSB: 'sust_issb',
  SUST_CDP: 'sust_cdp',
};

export const CALC_PAGE_TYPES = {
  BENCHMARK: 'benchmark',
  CUSTOM: 'custom',
  SUSTAINABILITY: 'sustainability',
};

export const CALCS_PER_PAGE = {
  [CALC_PAGE_TYPES.BENCHMARK]: [
    CALC_TYPES.CARBON,
    CALC_TYPES.LOCAL,
    CALC_TYPES.GLOBAL,
    CALC_TYPES.PROJECT,
  ],
  [CALC_PAGE_TYPES.CUSTOM]: [CALC_TYPES.CUSTOM_REPORT, CALC_TYPES.CUSTOM_FILES],
  [CALC_PAGE_TYPES.SUSTAINABILITY]: [
    CALC_TYPES.SUST_CSRD_ESRD,
    CALC_TYPES.SUST_GRI,
    CALC_TYPES.SUST_ISSB,
    CALC_TYPES.SUST_CDP,
  ],
};

export const CALC_DESCRIPTIONS = {
  [CALC_TYPES.CARBON]: `This tool helps you calculate your company's or projects'
  carbon footprints. It's based on the widely accepted GHG Carbon Footprint
  Protocol and has two parts: an Annual assessment and a Project assessment.`,
  [CALC_TYPES.LOCAL]: `This tool provides a comprehensive service that helps you
  understand your business's social and environmental impact by considering
  all relevant ESG indicators. It also includes the Carbon Footprint Calculator.`,
  [CALC_TYPES.GLOBAL]: `The ESG Benchmark is designed to provide a comprehensive sustainability
  analysis for companies, particularly larger ones, by comparing their Environmental,
  Social, and Governance (ESG) measures with those of the largest global companies.`,
  [CALC_TYPES.PROJECT]: ``,
  [CALC_TYPES.CUSTOM_REPORT]: `If you choose this method of submission, please complete the
  sections of our customised form and attach the relevant documentation.
  The AI system will thoroughly analyse the documentation provided and
  produce an initial report for subsequent validation by a qualified expert.
  The quality of the data depends on the accuracy and validity
  of the information provided by our data entry team.`,
  [CALC_TYPES.CUSTOM_FILES]: `Opting for this input method involves submitting a set of
  questions and the requisite documentation to ascertain your company's
  environmental impacts. Our AI will meticulously process the entered
  documentation, generating an initial report subject to subsequent
  validation by a qualified expert. The accuracy and reliability of
  the data hinge entirely on the precision of the information you furnish.`,
  [CALC_TYPES.SUST_CSRD_ESRD]: `The European Sustainability Reporting Standards (ESRS) were adopted by
  the European Commission in accordance with the Corporate Sustainability Reporting
  Directive (CSRD) that requires companies within its scope to report using a double
  materiality perspective.`,
  [CALC_TYPES.SUST_GRI]: `The GRI sustainability reporting standards cover topics ranging from
  biodiversity to tax, waste to emissions, diversity and equality to health and safety;
  and provide disclosure of both positive and negative impacts on the environment,
  society and the economy. The GRI standards are widely accepted and may be used by
  companies of all sizes, forms of ownership, organizational structures and industrial
  affiliations.`,
  [CALC_TYPES.SUST_ISSB]: `The ISSB standards provide an opportunity for companies' disclosures to
  be globally consistent and comparable across capital markets. The ISSB has consolidated
  and built on the work of market-led reporting initiatives comprising the Climate
  Disclosure Standards Board (CDSB), the Task Force for Climate-related Financial
  Disclosures (TCFD), the Value Reporting Foundation’s Integrated Reporting Framework
  and the Sustainability Accounting Standards Board (SASB).`,
  [CALC_TYPES.SUST_CDP]: `CDP provides a complete, reliable and verified system for climate
  disclosure (climate change and other elements of environmental information).
  This reporting framework helps companies communicate a comprehensive set of
  environmental information to their investors and stakeholders in clearly
  structured and accessible reports.`,
};

export const isReport = (type) =>
  type &&
  (type.toLowerCase() === CALC_TYPES.CUSTOM_REPORT ||
    type.toLowerCase() === CALC_TYPES.CUSTOM_FILES);

export const isESGcalc = (type) =>
  type &&
  (type.toLowerCase() === CALC_TYPES.CARBON ||
    type.toLowerCase() === CALC_TYPES.LOCAL ||
    type.toLowerCase() === CALC_TYPES.GLOBAL);

export const filterLocalCategory = (data, category) => {
  const filtered = data.filter(
    (sub) =>
      sub.esg.toLowerCase() === category &&
      sub.name !== 'Company calculator' &&
      sub.name !== 'Package calculator' &&
      sub.name !== 'Product calculator'
  );
  const newData = filtered.reduce(
    (res, sub) => ({
      ...res,
      ...sub.questions.reduce((obj, ques) => ({ ...obj, [ques.code]: ques }), {}),
    }),
    {}
  );
  return newData;
};

export const filterGlobalCategory = (data, type) => {
  const dimention = data.find((dim) => dim.code === type);
  return [
    ...dimention.categories
      .map((category) => ({
        ...category,
        questions: category.questions.filter((question) => question.subQuestions.length > 0),
      }))
      .filter((category) => category.questions.length > 0),
  ];
};

export const CALC_NAMES = {
  [CALC_TYPES.CARBON]: 'Carbon footprint calculator',
  [CALC_TYPES.LOCAL]: 'ESG Excellence (ESG Score Comparator)',
  [CALC_TYPES.GLOBAL]: 'ESG Benchmark (ESG Score Benchmarking)',
  [CALC_TYPES.PROJECT]: 'Project Сalculator',
  [CALC_TYPES.CUSTOM_REPORT]: 'Customizable Reporting Step-by-Step Upload',
  [CALC_TYPES.CUSTOM_FILES]: 'Customizable Reporting Massive Upload',
  [CALC_TYPES.SUST_CSRD_ESRD]: 'CSRD/ESRS',
  [CALC_TYPES.SUST_GRI]: 'GRI',
  [CALC_TYPES.SUST_ISSB]: 'ISSB',
  [CALC_TYPES.SUST_CDP]: 'CDP',
};

export const getCalculatordata = (result) => {
  if (result && result.type === CALC_TYPES.LOCAL)
    return [
      {
        title: 'CO2 calculator',
        code: 'company',
        score: `${Math.round(result?.results?.carbon ?? 0)} kg CO2e`,
        nested: true,
      },
      { title: 'Environmental', code: 'environmental', score: result.environmentalResult },
      { title: 'Social', code: 'social', score: result.socialResult },
      { title: 'Governance', code: 'governance', score: result.governanceResult },
    ];
  if (result && result.type === CALC_TYPES.CARBON)
    return [
      {
        title: 'CO2 calculator',
        code: 'company',
        score: `${Math.round(result?.results?.carbon ?? 0)} kg CO2e`,
        nested: true,
      },
    ];
  if (result && result.type === CALC_TYPES.PROJECT)
    return [
      {
        title: 'CO2 calculator',
        code: 'company',
        score: `${Math.round(result?.results?.carbon ?? 0)} kg CO2e`,
        nested: true,
      },
    ];
  if (result && result.type === CALC_TYPES.GLOBAL)
    return [
      {
        title: 'Environmental Dimension',
        code: 'globalEnvironmental',
        score: null,
        nested: true,
      },
      {
        title: 'Economic Dimension',
        code: 'globalEconomic',
        score: null,
        nested: true,
      },
      {
        title: 'Social Dimension',
        code: 'globalSocial',
        score: null,
        nested: true,
      },
    ];
};
