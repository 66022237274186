import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { GET_USER_RESULT, GET_RESULT_FILES } from '../../../../api';
import styles from './AnswersDetail.module.scss';
import LocalAnswers from '../../components/ByType/Local/DetailPage/LocalAnswers';
import CarbonAnswers from '../../components/ByType/Company/DetailPage/CarbonAnswers';
import GlobalAnswers from '../../components/ByType/Global/DetailPage/GlobalAnswers';
import { getCalculatordata, filterNulls } from '../../../../utils/calculatorUtils';
import { URL_SCORE_DETAILS } from 'constants/calculatorsPage';
import { getResultAnswer } from 'api/calc/endpoints/calc';

const AnswersDetail = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();

  const [resultFiles, setResultFiles] = useState([]);
  const [answersData, setAnswersData] = useState({});
  const [title, setTitle] = useState([]);
  const [result, setResult] = useState(null);
  const [calcSubtypes, setCalcSubtypes] = useState({});

  useEffect(() => {
    getResultAnswer({ id }).then((res) => {
      const { data, success } = res.data;
      if (success) {
        setAnswersData(data.answers);
        setResultFiles(data.files);
        setResult(data.result);
        setTitle(getCalculatordata(result)?.find((c) => c.code === type)?.title);
      }
    });
  }, []);

  // This effect also runs once when the component mounts. It sets the
  // `calcSubtypes` state based on the `type`.
  useEffect(() => {
    setCalcSubtypes(() => ({
      local: ['governance', 'social', 'environmental'].includes(type),
      company: ['company', 'project'].includes(type),
      global: ['globalEnvironmental', 'globalEconomic', 'globalSocial'].includes(type),
    }));
  }, []);

  return (
    <div className={styles.contentBlock}>
      <div className={styles.blockHeader}>
        <div
          className={styles.shareButton}
          onClick={() => navigate(`${URL_SCORE_DETAILS}/${type}/${id}`)}
        >
          <p>&lt; Back To Assessment</p>
        </div>
        <div className={styles.shareButton}>
          <img src="/images/shareIcon.png" alt="share" />
          <p>Share</p>
        </div>
      </div>
      <div className={styles.answersDetail}>
        <h3>{title}</h3>
        {!result && <div className={styles.noResult}>Loading...</div>}
        {result && (
          <div>
            {calcSubtypes.local && answersData?.local && (
              <LocalAnswers
                params={{ resultFiles, answersData: answersData?.local, type, title }}
              />
            )}
            {calcSubtypes.company && answersData?.carbon && (
              <CarbonAnswers
                params={{
                  resultFiles,
                  answersData: answersData?.carbon,
                  type,
                  title,
                  factorId: result?.ukGhgCollectionId,
                }}
              />
            )}
            {calcSubtypes.global && answersData?.global && (
              <GlobalAnswers
                params={{ resultFiles, answersData: answersData?.global, type, title }}
              />
            )}
            {((calcSubtypes.local && !answersData?.local) ||
              (calcSubtypes.company && !answersData?.carbon) ||
              (calcSubtypes.global && !answersData?.global)) && (
              <div className={styles.noResult}>No result</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AnswersDetail;
